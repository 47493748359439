import { deepCopy } from "../util/helper";
import { push } from "connected-react-router";
import { fetchRequest } from "../middleware/fetchMiddleware";

export const NAID_IS_LOADING = "NAID_IS_LOADING";
export const NAID_HAS_ERROR = "NAID_HAS_ERROR";
export const NAID_POST_SUCCESS = "NAID_POST_SUCCESS";

export const SET_SELECTED_CAMPAIGN_INDEX = "SET_SELECTED_CAMPAIGN_INDEX";
export const UPDATE_CAMPAIGN_NAME = "UPDATE_CAMPAIGN_NAME";
export const UPDATE_CAMPAIGN_STATUS = "UPDATE_CAMPAIGN_STATUS";
export const ADD_CAMPAIGN = "ADD_CAMPAIGN";
export const DELETE_CAMPAIGN = "DELETE_CAMPAIGN";

export function NAIDFetchData(userid, jwt) {
  return (dispatch) => 
    dispatch(fetchRequest("NAID", "POST", "/retrieveAllNAID", { userid, jwt }))
    .catch(e => console.log(e))
}


export function NAIDIsLoading(isLoading) {
  return dispatch => {
    dispatch({
      type: NAID_IS_LOADING,
      isLoading: isLoading
    });
  };
}

export function NAIDHasError(hasError) {
  return dispatch => {
    dispatch({
      type: NAID_HAS_ERROR,
      hasError: hasError
    });
  };
}

export function NAIDFetchSuccess(naid) {
  return dispatch => {
    dispatch({
      type: NAID_POST_SUCCESS,
      campaigns: convertNAID(naid)
    });
  };
}

export function setSelectedCampaignIndex(id) {
  return dispatch => {
    dispatch({
      type: SET_SELECTED_CAMPAIGN_INDEX,
      id: id
    });
  };
}

export function updateCampaignName(id, newName) {
  return dispatch => {
    dispatch({
      type: UPDATE_CAMPAIGN_NAME,
      id: id,
      newName
    });
  };
}

export function updateCampaignStatus(id, status) {
  return dispatch => {
    dispatch({
      type: UPDATE_CAMPAIGN_STATUS,
      id: id,
      status: status
    });
  };
}

function convertNAID(response) {
  return response.data;
}

export function addCampaign(campaign) {
  return dispatch => {
    dispatch({
      type: ADD_CAMPAIGN,
      campaign: campaign
    });
  };
}

export function addCampaignPost(campaignName, organizationId, campaignData = {}) {
  return (dispatch, getState) => {

    const params = {
      name: campaignName,
      organizationid: organizationId,
      campaignData,
    };

    let campaignid;

    return dispatch(fetchRequest("ADD_CAMPAIGN", "POST", "/campaigns/", params))
    .then(data => {
        campaignid = data.data.campaignid;
        return dispatch(
          addCampaign({
            name: campaignName,
            campaignid: campaignid,
            active: "notyet",
            organizationid: organizationId,
          })
        );
      })
      .then(data => dispatch(push(`/dashboard/campaigns/${campaignid}/`)))
      .catch(error => {
        dispatch(NAIDHasError(true));

        throw Error(error);
      });
  };
}

// For this to work we need jwt and adminid.
// We should put these in state.
export function addNewCampaign(campaignname,organizationid) {
  return (dispatch, getState) => {
    dispatch(addCampaignPost(campaignname, organizationid))
    .catch(e => {
      // This just prevents error propagation when there's a
      // unauthenticated error
      console.error(e);
    })
  };
}

// Copy the selected campaign to a new one.
// Neither the list nor login are copied.
// This updates both the local copy and the database.
/**
 * copyCampaign uses the add campaign method with a different body
 *
 * @param {bool} copyScript should the scipt be identical
 * @param {bool} copyPrompt should the prompt be identical
 * @param {bool} copyList should the list be identical
 * @param {bool} copyLogin should the login be identical
 */
export function copyCampaign(copyScript, copyPrompt, copyList, newOrgId) {
  return (dispatch, getState) => {
    const state = getState();
    const ncd = deepCopy(
      state.selectedCampaign.selectedCampaign.selectedCampaignData
    );

    if (newOrgId) {
      ncd.organizationid = newOrgId;
    }

    // Change the data that must be changed.
    ncd.name = ncd.name + "(New)";
    ncd.active = "notyet";
    if (copyScript === false) {
      ncd.script = [];
      ncd.initialConditionals = [];
      ncd.defaultFirstQuestionId = undefined;
    }
    if (copyPrompt === false) {
      ncd.prompt = [];
    }

    if (copyList === false) {
      ncd.list = {};
    } else {
      // If we're keeping the list then we clean it.
      // Set each phone number to open, no senderid,
      // reset the script index and empty the conversation.
      Object.keys(ncd.list).forEach(function(ky) {
        ncd.list[ky].status = "open";
        ncd.list[ky].senderid = "";
        ncd.list[ky].currentscriptindex = 0;
        ncd.list[ky].conversation = [];
      });
    }
    return dispatch(addCampaignPost(ncd.name, ncd.organizationid, ncd));
  };
}

export function deleteCampaignPost() {
  return (dispatch, getState) => {
    const state = getState();
    const id = state.NAID.selectedCampaignIndex;
    const params = {
      campaignid: id,
      jwt: state.auth.jwt
    };

    dispatch(fetchRequest("DELETE_CAMPAIGN", "POST", "/deleteCampaign", params))
      .then(data => {
        // TODO: Figure out what campaign is first in the display, not just the array.
        // 1. get the orgs
        // 2. get the campaigns for that org
        // 3. sort by campaignid
        // 4. get the first one
        const currentCampaignOrgId = state.NAID.campaigns.filter(c => c.campaignid === id)[0].organizationid;
        const firstCampaign = state.NAID.campaigns
          .filter(c => c.organizationid === currentCampaignOrgId && c.campaignid >= 0)
          .sort((a, b) => a.campaignid - b.campaignid)[0].campaignid;

        dispatch(push(`/dashboard/campaigns/${firstCampaign}/`));
        dispatch(deleteCampaign(state.NAID.selectedCampaignIndex));
        return data;
      })
      .catch(error => {
        dispatch(NAIDHasError(true));
        console.error(error);
      });
  };
}

export function deleteCampaign(index) {
  return dispatch =>
    dispatch({
      type: DELETE_CAMPAIGN,
      index: index
    });
}