import React, { Component } from "react";
import SelectSearch from 'react-select-search';
import { S160Field } from "../common/S160Field/S160Field";
import { LoadingSpinner } from "../LoadingSpinner";

class BandwidthTools extends Component {

  constructor(props) {
    super(props);

    this.state = {
      prevCampaignId: null,
      assignedLoaded: false,
      availableLoaded: false,
      assigned: {},
      available: [],
      refresh: false
    }
  }

  componentDidMount() {
    // NOTE: I've renamed the request states to avoid conflicts
    // getRegState: getAllRegistrations
    // assignCampaignState: assignBandwidthRegistrationToCampaign
    this.getAssigned();
    this.getAvailable();
  }

  getAssigned = async () => {
    
    this.setState({ prevCampaignId: this.props.campaignid, refresh: false });
    const { maxCampaignsPerRegistration, settings, } = await this.props.getAssignedRegistration();
    
    this.setState({
      prevCampaignId: this.props.campaignid,
      assignedLoaded: true,
      assigned: settings.find(setting => setting.campaign_list.includes(this.props.campaignid)) || {},
      maxCampaignsPerRegistration,
      refresh: false,
    });
  }

  getAvailable = async () => {
    // NOTE: I think the API should handle the maxCampaignsPerRegistration so that the users don't see unavailable registrations
    const { maxCampaignsPerRegistration, settings, } = await this.props.getAvailableRegistrations();
    this.setState({
      availableLoaded: true,
      available: settings.sort((a, b) => a.campaign_list.length > b.campaign_list.length ? 1 : -1),
      refresh: false,
    });
  }

  componentDidUpdate() {
    const { getRegState } = this.props;
    const { loading }  = getRegState;
    
    // Can't do anything while loading 
    if (loading) {
      return;
    }
    
    // On campaign change, reload selected data
    if (this.props.campaignid !== this.state.prevCampaignId || this.state.refresh) {
      this.getAssigned();
      return;
    }
  }

  unassignRegistration = async () => {
    console.log("Unassigning",this.state.assigned.settings_id, this.props.campaignid);
    await this.props.unassignBandwidthRegistration(this.state.assigned.settings_id, this.props.campaignid);
    this.setState({ assigned: {} });
    await this.getAvailable();
  }

  onDropdownChange = async (selectedBwSettingsId) => {
    const { settings } = await this.props.assignBandwidthRegistrationToCampaign(selectedBwSettingsId, this.props.campaignid, [false, this.props.campaignid]);
    this.setState({ assigned: settings.find(setting => setting.campaign_list.includes(this.props.campaignid)) || {} });
  }

  render() {
    const {
      has_unassigned_recipients,
      getRegState,
    } = this.props;

    if (getRegState.loading) {
      return <LoadingSpinner />;
    } 

    const { assigned: assignedBwSetting, available } = this.state;

    let dropdownValue;
    
    if ( assignedBwSetting ) {
      dropdownValue = assignedBwSetting.settings_id;
    } else {
      dropdownValue = null;
    }
    
    let dropdownList = assignedBwSetting.settings_id ?
      [assignedBwSetting]
      // Already sorted by by number of campaign assignments. Less at the top
      : available.filter(setting => setting.campaign_list.length < this.state.maxCampaignsPerRegistration)

    return (
      <div className="tile is-parent is-vertical">
        <h4 className="title is-4 has-text-centered">BANDWIDTH SETTINGS</h4>
        <div className="tile is-parent">
          <div className="tile is-child">
            <S160Field
              name="Subaccount ID"
              value={assignedBwSetting.subaccount_id}
              fallbackValue="-- Subaccount ID not found --"
            />
            <S160Field
              name="Location ID"
              value={assignedBwSetting.location_id}
              fallbackValue="-- Location ID not found --"
            />
            <S160Field
              name="Registration ID"
              value={assignedBwSetting.reg_id}
              fallbackValue="-- Registration ID not found --"
            />
            <S160Field
              name="Are there unassigned recipients?"
              value={has_unassigned_recipients ? "Yes" : "No"}
              fallbackValue="-- Unassigned recipients not found --"
            />
          </div>
          <div className="is-flex is-flex-direction-column">
            {/* TODO: Lock this when selected. Make the user deselect it before new selection */}
            <SelectSearch
              options={dropdownList.map(setting => ({ value: setting.settings_id, name: `${setting.settings_id} - ${setting.reg_id} (${setting.campaign_list.length})` }))}
              value={dropdownValue}
              // We want to disable the dropdown if there is a selected registration
              disabled={!!dropdownValue}
              search
              emptyMessage="No registrations found"
              placeholder="Choose a registration"
              onChange={this.onDropdownChange}
            />
            { !!dropdownValue && <button
              className="button is-danger mt-2"
              onClick={this.unassignRegistration}
            >
              Unassign Registration
            </button>}
          </div>
        </div>
      </div>
    );
  }
}

export default BandwidthTools;
