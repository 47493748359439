import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ModalContainer, {
  ModalBody,
  ModalHeader,
  ModalFooter
} from "./ModalContainer";

import { removeUserFromCampaign } from "../../redux/Users/actions";

class RemoveUserFromCampaign extends Component {
  render() {
    const {
      userid,
      campaignName,
      campaignid,
      removeUserFromCampaign,
      loading,
      userDataUpdated
    } = this.props;

    return (
      <ModalContainer
        name="Remove User from Campaign"
        customButtonClass="delete is-medium"
      >
        {modalState => (
          <React.Fragment>
            <ModalHeader>Remove User from Campaign</ModalHeader>
            <ModalBody>
              Are you sure you want to remove <b>{userid}</b> from{" "}
              <b>{campaignName}</b>?
            </ModalBody>
            <ModalFooter {...modalState}>
              <a
                className={"button is-info" + (loading && " is-loading")}
                onClick={() => {
                  return removeUserFromCampaign(userid, campaignid).then(() =>{
                    userDataUpdated();
                    modalState.toggleOpen();
                  });
                }}
              >
                Confirm
              </a>
            </ModalFooter>
          </React.Fragment>
        )}
      </ModalContainer>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      removeUserFromCampaign: (userid, campaignid) =>
        removeUserFromCampaign(userid, campaignid)
    },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(RemoveUserFromCampaign);
