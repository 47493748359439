import React, { Component } from "react";

import PromptEditorModal from "../components/modals/PromptEditorModal";

import { deepCopy, filterSample } from "../util/helper";

//----------------------------------------------------------------------------------------------------
// DCMMainScreenPrompt
//
// The main screen prompt manages the prompt.
// The main screen prompt receives the following props:
//
// selectedCampaignIndex  - The index of the current campaign.
// selectedCampaignData   - The data associated to the current campaign.
// setDeactivatingOverlay - Set the deactivating overlay for modal interaction forcing.
// updatePrompt           - Update a prompt.

class Prompt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      localPrompt: deepCopy(this.props.prompt),
      displayIndex: 0
    };
    this.shiftDisplayIndex = this.shiftDisplayIndex.bind(this);
  }
  // If we change campaigns while on the mainscreen then we need to update the local props.
  componentWillReceiveProps(newProps) {
    this.setState({
      localPrompt: deepCopy(newProps.prompt)
    });
  }
  shiftDisplayIndex() {
    var newDisplayIndex = this.state.displayIndex + 1;
    const phones = Object.keys(this.props.selectedCampaignData.list);
    if (newDisplayIndex == phones.length) {
      newDisplayIndex = 0;
    }
    this.setState({displayIndex: newDisplayIndex});
  }
  render() {
    const props = this.props;
    // const active = props.selectedCampaignData.active;

    // Pass in these props and it should work.
    const { active, list, prompt, updatePrompt, subject, getRandomListEntries } = props;

    const editMode = this.state.editMode;
    const phones = Object.keys(props.selectedCampaignData.list);
    return (
        <div className={"dcm-mainscreen-script" + (editMode ? "-editmode" : "")} style={{height: "100%", overflow: "auto"}}>
        {!editMode && (
            <PromptEditorModal
              displayIndex={this.state.displayIndex}
              shiftDisplayIndex={this.shiftDisplayIndex}
              prompt={prompt}
              list={props.selectedCampaignData.list}
              updatePrompt={updatePrompt}
              selectedCampaignIndex={props.selectedCampaignIndex}
              subject={subject}
              getRandomListEntries={getRandomListEntries}
            />
        )}
        <button className="button" onClick={() => getRandomListEntries(5)}>Randomize Sample</button>
        <hr />
        {this.state.localPrompt.map((q, qIndex) => {
          var sample = {
            unavailable: true,
            question: ''
          };
          if ((phones.length > 0) && (this.state.displayIndex !== undefined)) {
            sample.unavailable = false
            // Here is one of two places where we'll need to have a randomization.
            // Right now we only have the first 5 phone numbers' data loaded to save memory
            // so perhaps we should load more? We don't want to load them all so it's not
            // clear how we'll get a "good" random choice, whatever "good" means.
            const replacements = list[phones[this.state.displayIndex]].misc;
            sample.question = filterSample(q.question, replacements);
          }
          return (
            <div key={qIndex}>
              <div className="dcm-mainscreen-script-item-number">{qIndex + 1}</div>
              <div className="dcm-mainscreen-script-item">{q.question}</div>
              <div className="dcm-mainscreen-script-item-sample">
                <strong>Sample: </strong>
                {sample.unavailable ?
                  <span className="sample-unavailable">[no phone list available to sample]</span>
                :
                  <span>
                    {sample.question}
                  </span>
                }
              </div>
            </div>
          );
        })}
        <hr />
        <button className="button" onClick={() => getRandomListEntries(5)}>Randomize Sample</button>
      </div>
    );
  }
}

export default Prompt;
