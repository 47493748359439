import { fetchRequest } from "../middleware/fetchMiddleware";

export const ORG_IS_LOADING = "ORG_IS_LOADING";
export const ORG_HAS_ERROR = "ORG_HAS_ERROR";
export const ORG_GET_SUCCESS = "ORG_GET_SUCCESS";

export function addOrganizationPost(organizationname) {
  return (dispatch, getState) => {
    const params = {
      organizationname: organizationname,
      jwt: getState().auth.jwt
    };

    return dispatch(fetchRequest("ADD_ORGANIZATION", "POST", "/addorganization", params));
  };
}

export function orgIsLoading(isLoading) {
  return dispatch => {
    dispatch({
      type: ORG_IS_LOADING,
      isLoading: isLoading
    });
  };
}

export function orgHasError(hasError) {
  return dispatch => {
    dispatch({
      type: ORG_HAS_ERROR,
      hasError: hasError
    });
  };
}

export function orgSuccess(orgs) {
  return dispatch => {
    dispatch({
      type: ORG_GET_SUCCESS,
      organizations: orgs
    });
  };
}

export function retrieveCampaignDataDefaultsPost(orgid) {
  return (dispatch, getState) => {
    return dispatch(fetchRequest("CAMPAIGN_DATA_DEFAULTS", "POST", "/retrieveCampaignDataDefaults", {
      organizationid: orgid
    }))
  }
}

export function updateCampaignDataDefaultsPost(orgid, data) {
  return (dispatch, getState) => {
    return dispatch(fetchRequest("UPDATE_CAMPAIGN_DATA_DEFAULTS", "POST", "/updateCampaignDataDefaults", {
      data: data,
      organizationid: orgid
    }))
  }
}