import React from "react";

export const LoadingSpinner = ({loading}) => (loading ? <div
    className="loader "
    style={{
      margin: "0",
      width: "20px",
      height: "20px",
      position: "relative",
      zIndex: 50
    }}
  /> : null);