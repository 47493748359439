// Displays the analytics via AnalyticsTable.
// This includes the checkboxes which manage the analytics as well as the download of those analytics.
// This shows organizations, campaigns, and agents that the user has access to.
// These are retrieved from the variables organizations, campaigns, users which come via props from AnalyticsContainer.

import React, { Component } from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { updateUserAnalyticsCheckboxConfig } from "../../redux/Users/actions";
import { vitalsDataPost } from "../../actions/vitals";
import { FieldVisibilityTable } from "./FieldVisibilityTable";
import Tabs from "../tabs/NewTabs";

import { HEADERS, CHECKBOX_VISIBILITY_TABLE_DEFAULTS } from "./constants";
import AnalyticsTable from "./AnalyticsTable";
class Analytics extends Component {
  constructor(props) {
    super(props);

    this.state = {
      localCheckboxConfig: props.analyticscheckboxconfig || CHECKBOX_VISIBILITY_TABLE_DEFAULTS,
      dirty: false,
    };
    this.handleClick = this.handleClick.bind(this);
    this.update = this.update.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.analyticscheckboxconfig && !state.localCheckboxConfig) {
      return {
        localCheckboxConfig: props.analyticscheckboxconfig,
      };
    }
    return null;
  }

  handleClick(e) {
    var acc = { ...this.state.localCheckboxConfig };
    acc[e.target.name] = e.target.checked;

    this.setState({ localCheckboxConfig: acc });
  }

  update() {
    // Could this be done in Redux?
    this.props
      .updateUserAnalyticsCheckboxConfig(this.state.localCheckboxConfig)
      .then(() => {
        this.props.vitalsDataPost();
        this.setState({ localCheckboxConfig: {} });
      });
  }

  render() {
    const {
      tabs,
      selectedOrganizationId,
      selectedCampaignId,
      scope,
      showSnapshots,
      showFieldTable,
      showClipboardButton,
      verticalView,
      headerOrder,
    } = this.props;

    let fieldVisibilityTable;
    if (showFieldTable) {
      fieldVisibilityTable = (
        <FieldVisibilityTable
          handleClick={this.handleClick}
          update={this.update}
          headers={HEADERS}
          checkboxes={this.state.localCheckboxConfig}
        />
      );
    }
    
    return (
      <div className="analytics">
        <div className="tile is-ancestor">
          <div className="tile is-parent is-vertical is-12">
            <div className="tile is-child " />
            <Tabs
              defaultTab="campaigns"
              size="medium"
              style="boxed"
              alignment="left"
            >
              {tabs.map((tab) => {
                return (
                  <AnalyticsTable
                    key={"tab-container-" + tab.name}
                    dataName={tab.dataName}
                    tabName={tab.name}
                    analyticscheckboxconfig={this.state.localCheckboxConfig}
                    scope={scope}
                    headers={HEADERS}
                    organizationid={selectedOrganizationId}
                    campaignid={selectedCampaignId}
                    active={"active"}
                    showSnapshots={showSnapshots}
                    showClipboardButton={showClipboardButton}
                    verticalView={verticalView}
                    headerOrder={headerOrder}
                  />
                );
              })}
            </Tabs>

            <br />

            {fieldVisibilityTable}
          </div>
        </div>
      </div>
    );
  }
}

Analytics.defaultProps = {
  showSnapshots: true,
  showFieldTable: true,
  showClipboardButton: true,
  verticalView: true,
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateUserAnalyticsCheckboxConfig,
      vitalsDataPost,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(Analytics);
