import React, { Component } from "react";
import PropTypes from "prop-types";

import { QUESTION_TYPES, ADMIN_QUESTION_TYPES } from "./scriptUtil";
import { PlusButtonCircle } from "../generic/RoundButtons/RoundButtons";
import { AddQuestionTypeSelectOverlay } from "./AddQuestionTypeSelectOverlay";

class AddQuestion extends Component {
  constructor(props) {
    super(props);

    this.wrapper = React.createRef();

    this.state = {
      typeListVisible: false,
    };
  }

  static propTypes = {
    showAdminQuestionTypes: PropTypes.bool.isRequired,
    qid: PropTypes.string.isRequired,
    qIndex: PropTypes.number.isRequired,
    addQuestion: PropTypes.func.isRequired
  };

  // This solution from StackOverflow was great. I modified it so instead of adding
  // the listener on mount, we add it on click.
  // https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
  componentWillUnmount() {
    document.removeEventListener("mousedown", (e) => this.clickOffButton);
  }

  componentDidMount() {
    this.setState({
      questionTypeList:
        this.props.showAdminQuestionTypes
          ? [...QUESTION_TYPES, ...ADMIN_QUESTION_TYPES]
          : QUESTION_TYPES,
      itemId: `add-question-wrapper-${this.props.qid}`,
    });
  }

  toggleListVisible = (e, visible) => {
    this.setState(
      {
        typeListVisible:
          visible !== undefined ? visible : !this.state.typeListVisible,
      },
      () => {
        // Only add if the component is already visible to prevent listener overload
        if (this.state.typeListVisible) {
          document.addEventListener("mousedown", (e) => this.clickOffButton(e));
        } else {
          document.removeEventListener("mousedown", (e) =>
            this.clickOffButton(e)
          );
        }
      }
    );
  };

  /**
   * Check if the click event happened on the dropdown then make invisible if not
   */
  clickOffButton(e) {
    if (this.wrapper.current && !this.wrapper.current.contains(e.target)) {
      this.toggleListVisible(e, false);
    }
  }

  addQuestionAndMakeListInvisible = (e) => {
    this.props.addQuestion(
      this.props.qIndex + 0.5,
      e.target.getAttribute("value")
    );
    this.toggleListVisible(e, false);
  };

  render() {
    return (
      <div id={this.state.itemId} ref={this.wrapper}>
        <PlusButtonCircle
          callback={this.toggleListVisible}
          className="success"
          classSize="is-medium"
          label="Add Question Below This One"
          id={`add-question-button-${this.props.qid}`}
        />
        {this.state.typeListVisible && (
          <AddQuestionTypeSelectOverlay
            qid={this.props.qid}
            questionTypeList={this.state.questionTypeList}
            addQuestionAndMakeListInvisible={
              this.addQuestionAndMakeListInvisible
            }
          />
        )}
      </div>
    );
  }
}

export default AddQuestion;
