// Shows and manages users via UserFilters and via ListWithLoading.
// This also contains the ConfirmDeleteUserModal which is activated and filled for a particular user
// when the user's corresponding delete is pressed.

import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import ConfirmDeleteUserModal from "../components/modals/ConfirmDeleteUserModal";

import UserFilters from "../components/UserFilters";
import List from "../components/List";
import { getUsers, deleteUser, addUserAuthy } from "../redux/Users/actions";
import { withLoadingAndError } from "../components/higher-order/ComponentWithLoadingAndError";

const ListWithLoading = withLoadingAndError(List);

const columnHeaders = [
  { header: "User Id", title: "User Id", accessor: "userid" },
  { header: "Role", title: "User Role", accessor: "role" },
  { header: "Campaigns", title: "Campaigns", accessor: "campaigns" },
  { header: "Actions", title: "Actions", accessor: "actions" },
];

class UsersComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      changed: false,
      deleteUserModalOpen: false,
      deleteUserID: undefined,
    };
    this.userDataUpdated = this.userDataUpdated.bind(this);
    this.deleteUserOpenModal = this.deleteUserOpenModal.bind(this);
    this.deleteUserNew = this.deleteUserNew.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  componentDidMount() {
    if (this.props.userData.userData.users) {
      if (this.props.auth.organizationid > 0) {
        this.props.getUsers(this.props.auth.organizationid, undefined);
      } else {
        this.props.getUsers(undefined, undefined);
      }
    }
  }

  componentDidUpdate() {
    const { userData } = this.props;
    if (
      this.state.changed &&
      !userData.addUserAuthy.loading &&
      !userData.deleteUser.loading &&
      !userData.updateUserPermissions.loading &&
      !userData.addUserToCampaign.loading &&
      !userData.removeUserFromCampaign.loading
    ) {
      if (this.props.auth.organizationid > 0) {
        this.props.getUsers(this.props.auth.organizationid, undefined);
      } else {
        this.props.getUsers(undefined, undefined);
      }
      this.setState({
        changed: false,
      });
    }
  }

  userDataUpdated() {
    this.setState({
      changed: true,
    });
  }

  deleteUserOpenModal(id) {
    this.setState({
      deleteUserModalOpen: true,
      deleteUserID: id,
    });
  }

  toggleModal() {
    this.setState({ deleteUserModalOpen: !this.state.deleteUserModalOpen });
  }

  deleteUserNew() {
    this.setState({ deleteUserModalOpen: false });
    this.props.deleteUser(this.state.deleteUserID);
    this.setState({
      changed: true,
    });
  }

  /**
   * Wraps the addUserAuthy and makes sure to reload data on completed request.
   * @param {*} email
   * @param {*} phone
   * @param {*} password
   * @param {*} permissionlevel
   * @param {*} organizationid
   * @param {*} userid
   * @returns promise of the request
   */
  addUser = (
    email,
    phone,
    password,
    permissionlevel,
    organizationid,
    userid
  ) => {
    return this.props
      .addUserAuthy(
        email,
        phone,
        password,
        permissionlevel,
        organizationid,
        userid
      )
      .then(() => {
        this.setState({
          changed: true,
        });
      });
  };

  render() {
    const { users, loading, error } = this.props.userData.userData;
    // Remove inactive campaigns to make it clearer
    const campaigns = this.props.campaigns
    .filter(
      (c) => c.active !== "deactivated"
    );

    return (
      <div className="tile is-ancestor">
        <div className="tile is-parent is-vertical">
          <ConfirmDeleteUserModal
            open={this.state.deleteUserModalOpen}
            toggleModal={this.toggleModal}
            userID={this.state.deleteUserID}
            actionName="Delete User"
            actionCallback={this.deleteUserNew}
            buttonClass="button is-danger"
          />

          <UserFilters
            campaigns={campaigns}
            userData={this.props.userData}
            userid={this.props.userid}
            deleteUser={this.deleteUserOpenModal}
            organizationarray={this.props.organizationarray}
            permissionlevel={this.props.permissionlevel}
            userDataUpdated={this.userDataUpdated}
            addUser={this.addUser}
          >
            {(filterState) =>
              users && (
                <ListWithLoading
                  headers={columnHeaders}
                  list={filterState.list}
                  loading={loading}
                  error={error}
                />
              )
            }
          </UserFilters>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    userData: state.userData,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addUserAuthy: (
        email,
        phone,
        password,
        permissionlevel,
        organizationid,
        userid
      ) =>
        addUserAuthy(
          email,
          phone,
          password,
          permissionlevel,
          organizationid,
          userid
        ),
      getUsers: (orgid, campaignid) => getUsers(orgid, campaignid),
      deleteUser: (userid) => deleteUser(userid),
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UsersComponent);
