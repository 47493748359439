import React, {Component} from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getChargeHistory, downloadChargeHistory } from "../../actions/billing";
import { Charges } from "./Charges";
import { LoadingSpinner } from "../LoadingSpinner";
import { centsToDollars } from "../../util/helper";

class ChargesContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentPage: 0
        };

        this.flipPage = this.flipPage.bind(this);
        this.download = this.download.bind(this);
    }

    componentDidMount() {
        // if (!this.props.loading && !this.props.error && !this.props.data) {
            // The key is to set this up so that the same component will never have both.
            this.props.getChargeHistory(this.props.campaignid, this.props.organizationid);
        // }
    }

    componentDidUpdate() {
        const {loading, error, data} = this.props.chargeHistory;

        if (!this.state.processedData && !loading && !error) {
            // The key is to set this up so that the same component will never have both.
            this.props.getChargeHistory(this.props.campaignid, this.props.organizationid);
        }
    }

    static getDerivedStateFromProps(props, state) {
        const {loading, error, data} = props.chargeHistory;

        if (props.organizationid !== state.previousOrganizationId) {
            console.log("Clear")
            return {
                processedData: null,
                previousOrganizationId: props.organizationid
            }
        }

        if (!loading && !error && data && (!state.processedData || state.dirty)) {
            // Super simple pagination logic.
            let start = 0;
            let end = props.itemsPerPage;
            console.log("Process")


            if (state.currentPage > 0) {
                start += props.itemsPerPage*state.currentPage;
                end += props.itemsPerPage*state.currentPage;
            }
            return {
                processedData: data.slice(start, end).map(charge => {
                    const campaign = props.campaigns.find(c => c.campaignid === charge.campaignid);
                    const org = props.campaigns.find(o => o.organizationid === charge.organizationid);
                    return ({
                    ...charge,
                    campaignname: campaign ? campaign.name : "",
                    // organizationname: org ? org.organizationname : "",
                    amount: centsToDollars(parseFloat(charge.amount)).toFixed(2)
                })}),
                totalPages: Math.ceil(data.length / props.itemsPerPage),
                dirty: false
            }
        }

        return null;
    }

    flipPage(page) {
        this.setState({
            currentPage: page,
            dirty: true
        });
    }

    download() {
        this.props.downloadChargeHistory(undefined, this.props.organizationid);
    }

    render() {
        const {loading, error} = this.props.chargeHistory;

        return <>
            {(!loading && !error && this.state.processedData) && <Charges download={this.download} flipPage={this.flipPage} totalPages={this.state.totalPages} currentPage={this.state.currentPage} charges={this.state.processedData}/>}
            {loading && <LoadingSpinner loading={loading}/>}
            {error && <div className="notification is-danger" >Error! Something went wrong</div> }
        </>;
    }
}

const mapStateToProps = state => ({
    chargeHistory: state.billing.chargeHistory
});

const mapDispatchToProps = dispatch =>
bindActionCreators(
    {
    getChargeHistory: (campaignid, orgid) => getChargeHistory(campaignid, orgid),
    downloadChargeHistory: (campaignid, orgid) => downloadChargeHistory(campaignid, orgid)
    },
    dispatch
);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChargesContainer);
