import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ModalContainer, {
  ModalBody,
  ModalHeader,
  ModalFooter
} from "../modals/ModalContainer";

import { updateTextSettings } from "../../redux/SelectedCampaign/actions";

class TextToolsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      valid: true,
      loading: false
    };

    this.toggleOpen = this.toggleOpen.bind(this);
    this.updateTextSettings = this.updateTextSettings.bind(this);
    this.onChangeField = this.onChangeField.bind(this);
  }

  updateTextSettings(toggleOpen) {
    if (this.state.dirty && !this.state.loading) {
      this.setState({error: ""})
      this.props
        .updateTextSettings(this.props.campaignid, {
          recipientbatchsize: this.state.recipientbatchsize,
          actionablethreshold: this.state.actionablethreshold
        })
        .then(result => {
          toggleOpen();
          this.setState({
            dirty: false
          })
        })
        .catch(err => {
          this.setState({ error: "Could not complete operation" });
        });
    }
  }

  onChangeField(e) {
    this.setState({
      [e.target.name]: e.target.value,
      dirty: true
    });
  }

  toggleOpen() {
    this.setState({
      open: !this.state.open
    });
  }

  render() {
    const { headerText } = this.props;
    return (
      <ModalContainer name="Edit Text Settings" customButtonClass={"button is-light is-fullwidth"}>
        {modalState => (
          <React.Fragment>
            <ModalHeader>
              Text Settings: &nbsp;<strong>{headerText}</strong>
            </ModalHeader>
            <ModalBody>
              <div className="field">
                <label className="label">New Recipient Batch Amount</label>
                <div className="control">
                  <input
                    className="input"
                    type="number"
                    name="recipientbatchsize"
                    onBlur={this.onChangeField}
                    defaultValue={this.props.recipientbatchsize}
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Threshold for loading more conversations</label>
                <div className="control">
                  <input
                    className="input"
                    type="number"
                    name="actionablethreshold"
                    onBlur={this.onChangeField}
                    defaultValue={this.props.actionablethreshold}
                  />
                </div>
              </div>
              <p className={"help is-danger"}>{this.state.error}</p>
            </ModalBody>
            <ModalFooter {...modalState}>
              <a
                className={
                  "button is-info " + (this.state.loading && "is-loading")
                }
                disabled={this.state.loading}
                onClick={() => this.updateTextSettings(modalState.toggleOpen)}
              >
                Save
              </a>
            </ModalFooter>
          </React.Fragment>
        )}
      </ModalContainer>
    );
  }
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateTextSettings: (campaignid, batchAmount) =>
        updateTextSettings(campaignid, batchAmount)
    },
    dispatch
  );
export default connect(
  null,
  mapDispatchToProps
)(TextToolsModal);
