import React from "react";
import { CurrencyInputField } from "../generic/CurrencyInputField";

export const OrganizationDefaults = ({
  data,
  setStateItem,
  validate,
  valid,
  loading,
  changed,
  save }) => (
  <div>
    <div className="tile is-parent is-vertical">
      <div className="content">
        <p>These settings will be applied to newly created Campaigns by default.</p>
      </div>
      <h4 className="title is-4 has-text-centered"> DEFAULT TWILIO SETTINGS</h4>
      <div className="tile is-parent">
        <div className="tile is-child">
          <div className="field">
            Twilio SID
            <label className="label">
              <input
                className="input"
                value={data.twiliosid}
                name="twiliosid"
                onChange={setStateItem}
                onBlur={validate}
              />
            </label>
          </div>
          <div className="field">
            Twilio Token
            <label className="label">
              <input
                className="input"
                value={data.twiliotoken}
                name="twiliotoken"
                onChange={setStateItem}
                onBlur={validate}
              />
            </label>
          </div>
          <div className="field">
            Number of Copilots
            <label className="label">
              <input
                className="input"
                type="number"
                value={data.numberofcopilot}
                name="numberofcopilot"
                onChange={setStateItem}
                onBlur={validate}
              />
            </label>
          </div>
          <div className="field">
            Number of Phone Numbers per Grouping
            <label className="label">
              <input
                className="input"
                type="number"
                value={data.pnpercopilot}
                name="pnpercopilot"
                onChange={setStateItem}
                onBlur={validate}
              />
            </label>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div className="tile is-parent is-vertical">
      <h4 className="title is-4  has-text-centered"> DEFAULT COSTS</h4>
      <div className="tile is-parent">
        <div className="tile is-child">
          <div className="field">
            <div className="field">
              Total Agent Hours
              <label className="label">
                <input
                  className="input"
                  value={data.totalagenthours}
                  name="totalagenthours"
                  type="number"
                  onChange={setStateItem}
                  onBlur={validate}
                />
              </label>
            </div>
            <div className="field">
              Cost per Agent Hour
              <CurrencyInputField
                 className="input"
                 value={data.costperagenthour}
                 name="costperagenthour"
                 type="number"
                 min="0.01"
                 step="0.1"
                 onChange={setStateItem}
                 onBlur={validate}
              />
            </div>
            <div className="field">
              Cost per SMS
              <CurrencyInputField
                 className="input"
                 value={data.smssurcharge}
                 name="smssurcharge"
                 type="number"
                 min="0.01"
                 step="0.1"
                 onChange={setStateItem}
                 onBlur={validate}
              />
            </div>
            <div className="field">
              Spend Limit
              <CurrencyInputField
                className="input"
                value={data.spendlimit}
                name="spendlimit"
                type="number"
                min="0.01"
                step="1"
                onChange={setStateItem}
                onBlur={validate}
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <a
      className={`button is-info  ${((!valid || !changed) ? "is-static " : "")} ${loading ? "is-loading " : ""}`}
      onClick={save}
    >
      Save
    </a>
    {changed && <p className="help is-info">There are unsaved changes</p>}
    {!valid && <p className="help is-danger">Some fields are not valid.</p>}
  </div>
);


