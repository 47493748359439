import React from "react";
import { QuestionWrapper } from "./QuestionWrapper";
import { QuestionConditionals } from "./QuestionConditionals";

export const PanelOptIn = props => {
  const { q, qIndex, warnings, validated, permission } = props;
  return (
    <QuestionWrapper
      {...props}

      lockQuestionText={permission !== "SYSTEM_ADMIN"}
      lockId={true}
      disableUp={true}
      disableDown={true}
    >
      <table className="closedq-answers">
        <thead>
          <td className="value">Value</td>
          <td>Text</td>
        </thead>
        {q.answers.map((ans, ansIndex) => (
          <tbody key={ansIndex}>
            <tr key={ansIndex}>
              <td className="value">{ans.value}</td>
              <td className="answer">{ans.text}</td>
            </tr>
          </tbody>
        ))}
      </table>
      <QuestionConditionals 
        {...q}
        qIndex={qIndex}
        {...props}
      />
    </QuestionWrapper>
  );
};
