import {
    faCircle,
    faDotCircle,
  } from "@fortawesome/free-solid-svg-icons";
  
  export const statusMap = {
    active: {
      color: "success",
      icon: faCircle
    },
    closed: {
      color: "dark",
      icon: faCircle
    },
    notyet: {
      color: "light",
      icon: faCircle
    },
    deactivated: {
      color: "danger",
      icon: faCircle
    },
    sandbox: {
      color: "primary",
      icon: faCircle
    },
    paused: {
      color: "warning",
      icon: faCircle
    },
    prelaunch: {
      color: "success",
      icon: faDotCircle
    }
  };
  