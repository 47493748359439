import reduceReducers from "reduce-reducers";
import {
  AUTH_IS_LOADING,
  AUTH_HAS_ERROR,
  AUTH_SUCCESS,
  LOGOUT_SUCCESS,
} from "./actions";
import { USER_ROLES_LOOKUP_TABLE } from "../../util/constants";
import { createAddErrorMsgReducer } from "../../util/reusableReducers";
const initialState = {
  jwt: "",
  adminid: "",
  error: false,
  loading: false,
  message: "",
  matchedPassword: false,
  email: "",
};

function authReducer(state = initialState, action) {
  switch (action.type) {
    case AUTH_IS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case AUTH_HAS_ERROR:
      return {
        ...state,
        error: action.hasError,
        message: action.errorMessage,
        loading: false,
        adminid: "crap",
        jwt: "crap",
        userid: "crap",
        matchedPassword: false,
      };
    case "LOGIN_POST_SUCCESS":
      return {
        ...state,
        matchedPassword: true,
        email: action.data.email,
      };
    case "VERIFY_TOKEN_IS_LOADING":
      return {...state, loading: true, error: false};
    case "VERIFY_TOKEN_HAS_ERROR":
    return {...state, loading: false, error: true};
    case "VERIFY_TOKEN_POST_SUCCESS":
      const { data, userid, permissionlevel } = action.data;
      return {
        ...state,
        error: false,
        message: "Welcome!",
        loading: false,
        jwt: data,
        userid: userid,
        permissionlevel: permissionlevel,
        matchedPassword: false,
        permission: USER_ROLES_LOOKUP_TABLE[permissionlevel],
      };
    case AUTH_SUCCESS:
      return {
        ...state,
        error: false,
        message: "Welcome!",
        loading: false,
        jwt: action.jwt,
        userid: action.userid,
        analyticscheckboxconfig: action.analyticscheckboxconfig,
        organizationid: action.organizationid,
        organizationarray: action.organizationarray,
        permissionlevel: action.permissionlevel,
        permission: USER_ROLES_LOOKUP_TABLE[action.permissionlevel],
      };
    case "UPDATE_PERMISSION":
      return {
        ...state,
        permission: action.permission,
      };
    case "CLEAR_DATA":
    case "LOGOUT_POST_SUCCESS":
    case LOGOUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
}

const authReducerErr = createAddErrorMsgReducer("VERIFY_TOKEN");
export default function(state, action) {
  return authReducerErr(authReducer(state, action), action);
}
