// Since it's a pain to do with bulma css, just use icons
import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleDown,
  faArrowCircleUp,
  faPlusCircle,
  faMinusCircle,
  faTimesCircle as timesCircleSol,
  faTimes,
  faPlus,
  faChevronDown,
  faChevronUp,
  faCopy,
  faPaste
} from "@fortawesome/free-solid-svg-icons";

// import {
//   faTimesCircle as timesCircleReg
// } from "@fortawesome/free-regular-svg-icons";

export const GenericIconButton = ({
  callback,
  icon = faPlusCircle,
  className = "info",
  size,
  classSize,
  position = "left",
  disabled = false,
  label = null,
  id
}) => (
  <a onClick={disabled ? () => { } : callback} disabled={disabled} id={id} label={label} Title={label}>
    <span class={`icon has-text-${className} ${disabled ? "disabled" : ""}` + className}>
      <FontAwesomeIcon
        icon={icon}
        size={size}
        className={"icon " + " is-" + position + " " + classSize}
      />
    </span>
  </a>
);

export const DownButton = props => (
  <GenericIconButton {...props} icon={faArrowCircleDown} />
);
export const UpButton = props => (
  <GenericIconButton {...props} icon={faArrowCircleUp} />
);
export const PlusButtonCircle = props => (
  <GenericIconButton {...props} icon={faPlusCircle} />
);
export const PlusButton = props => (
  <GenericIconButton {...props} icon={faPlus} />
);
export const MinusButton = props => (
  <GenericIconButton {...props} icon={faMinusCircle} />
);
export const TimesButton = props => (
  <GenericIconButton {...props} icon={faTimes} />
);
export const TimesCircleButton = props => (
  <GenericIconButton {...props} icon={timesCircleSol} />
);
export const CopyButton = props => (
  <GenericIconButton {...props} icon={faCopy} />
);
export const PasteButton = props => (
  <GenericIconButton {...props} icon={faPaste} />
);

export const ToggleChevronButton = props => (
  <GenericIconButton {...props} icon={props.up ? faChevronUp : faChevronDown} />
);