import React from "react";
import {
    PlusButtonCircle,
    PlusButton,
    MinusButton,
    TimesCircleButton,
    TimesButton,
    UpButton,
    DownButton,
  } from "../generic/RoundButtons/RoundButtons";
  
export const ConditionalTable = ({
    q: question,
    qIndex: questionIndex,
    warnings,
    handleConditionalChange,
    handleDefaultNextChange,
    deleteConditional,
    addConditional,
    getQuestionsAfter,
    validated
}) => (
  <table className="flow-logic">
    <thead>
      <tr>
        <td className="value">Conditional</td>
        <td className="goto">GOTO</td>
      </tr>
    </thead>
    <tbody>
      {question.conditionalNexts.map((logic, cIndex) => (
        <tr key={`row-${cIndex}`}>
          <td className="conditional">
            <input
              name="condition"
              type="text"
              rows="1"
              className="input"
              value={logic.condition}
              onChange={(e) => handleConditionalChange(questionIndex, cIndex, e)}
            />
            {warnings && validated && (
              <p className="help is-danger">
                {warnings.localScript[questionIndex].conditionalNexts[cIndex].condition}
              </p>
            )}
          </td>
          <td className="goto">
            <select
              name="goto"
              className="dropdown"
              value={logic.goto}
              onChange={(e) => handleConditionalChange(questionIndex, cIndex, e)}
            >
              {getQuestionsAfter(questionIndex).map((q, qid) => (
                <option key={`goto-${cIndex}-${qid}`} value={q.id}>
                  {q.id}
                </option>
              ))}
            </select>
          </td>
          <td className="action-button">
            <TimesButton
              callback={() => deleteConditional(questionIndex, cIndex)}
              className="danger"
              size="lg"
            />
          </td>
        </tr>
      ))}
      <tr onClick={() => addConditional(questionIndex)}>
        <td colSpan="2" />
        <td className="action-button">
          <PlusButton className="success" size="lg" />
        </td>
      </tr>
      <tr>
        <td className="conditional">
          <input
            name="condition"
            type="text"
            rows="1"
            className="input"
            value="DEFAULT"
            disabled={true}
          />
        </td>
        <td>
          <select
            className="dropdown"
            name="defaultNext"
            value={question.defaultNext}
            onChange={(e) => handleDefaultNextChange(questionIndex, e)}
          >
            {getQuestionsAfter(questionIndex).map((qA) => (
              <option key={`default-${questionIndex}-${qA.id}`} value={qA.id}>
                {qA.id}
              </option>
            ))}
          </select>
        </td>
      </tr>
    </tbody>
  </table>
);
