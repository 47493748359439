import * as Yup from "yup";

export const INIT_VALUES = {
  brandId: "",
  vertical: "",
  usecase: "POLLING_VOTING",
  subUsecases: "",
  description: "",
  embeddedLink: "",
  embeddedPhone: "",
  numberPool: "",
  subscriberOptIn: true,
  subscriberOptOut: true,
  autoRenewal: true,
  subscriberHelp: true,
  ageGated: false,
  directLending: false,
  affiliateMarketing: false,
  sample1: "",
  sample2: "",
  sample3: "",
  sample4: "",
  sample5: "",
  helpMessage: "",
  messageFlow: "",
  optoutMessage: "",
};

export const VERTICAL_DROPDOWN_FIELD_OPTIONS = [
  { display: "Real Estate", field: "REAL_ESTATE" },
  { display: "Healthcare", field: "HEALTHCARE" },
  { display: "Energy", field: "ENERGY" },
  { display: "Entertainment", field: "ENTERTAINMENT" },
  { display: "Retail", field: "RETAIL" },
  { display: "Agriculture", field: "AGRICULTURE" },
  { display: "Insurance", field: "INSURANCE" },
  { display: "Education", field: "EDUCATION" },
  { display: "Hospitality", field: "HOSPITALITY" },
  { display: "Financial", field: "FINANCIAL" },
  { display: "Gambling", field: "GAMBLING" },
  { display: "Construction", field: "CONSTRUCTION" },
  { display: "NGO", field: "NGO" },
  { display: "Manufacturing", field: "MANUFACTURING" },
  { display: "Government", field: "GOVERNMENT" },
  { display: "Technology", field: "TECHNOLOGY" },
  { display: "Communication", field: "COMMUNICATION" },
];

export const STANDARD_CAMPAIGN_TYPES = [
  { display: "2FA", field: "2FA" },
  { display: "Account Notification", field: "ACCOUNT_NOTIFICATION" },
  // { display: "", field: "CONVERSATIONAL" },
  { display: "Delivery Notification", field: "DELIVERY_NOTIFICATION" },
  { display: "Fraud Alert Messaging", field: "FRAUD_ALERT" },
  // { display: "", field: "CUSTOMER_CARE" },
  { display: "Higher Education", field: "HIGHER_EDUCATION" },
  { display: "Low Volume Mixed", field: "LOW_VOLUME" },
  { display: "Marketing", field: "MARKETING" },
  { display: "Mixed", field: "MIXED" },
  { display: "Polling and Voting", field: "POLLING_VOTING" },
  {
    display: "Public Service Announcement",
    field: "PUBLIC_SERVICE_ANNOUNCEMENT",
  },
  { display: "Security Alert", field: "SECURITY_ALERT" },
  // { display: "SOLE_PROPRIETOR", field: "SOLE_PROPRIETOR" },
];
export const SPECIAL_CAMPAIGN_TYPES = [
  { display: "Agents Franchises", field: "AGENTS_FRANCHISES" },
  { display: "Carrier Exempt", field: "CARRIER_EXEMPT" },
  { display: "Charity", field: "CHARITY" },
  { display: "Emergency", field: "EMERGENCY" },
  { display: "Political", field: "POLITICAL" },
  { display: "Proxy", field: "PROXY" },
  { display: "Social", field: "SOCIAL" },
  { display: "Sweepstake", field: "SWEEPSTAKE" },
  { display: "K-12 Education", field: "K12_EDUCATION" },
];

export const CAMPAIGN_REGISTRATION_OPTIONS_TEXT = {
  // subscriberOptOut: "Are you collecting and processing consumer opt-out?",
  // subscriberHelp:
  //   "Have you implemented a response to the HELP keyword informing customers of how they can contact the message sender?",
  numberPool: "Will more than 50 TNs be used for this campaign?",
  directLending:
    "Will the campaign include content related to direct lending or other loan arrangements?",
  embeddedLink: "Are you using an embedded link of any kind?",
  embeddedPhone:
    "Are you using any embedded phone number beyond the contact number in the HELP response?",
  affiliateMarketing:
    "Is affiliate marketing being used (or was used in the creation of the campaign)?",
  ageGated:
    "Will the campaign include any age-gated content as defined by carrier and CTIA guidelines?",
};
export const CAMPAIGN_REGISTRATION_OPTIONS_REQUIRED_TEXT = {
  autoRenewal: "Auto renew campaign?",
  subscriberOptIn: "Are you collecting and processing consumer opt-in?",
  subscriberOptOut: "Are you collecting and processing consumer opt-out?",
  subscriberHelp:
    "Have you implemented a response to the HELP keyword informing customers of how they can contact the message sender?",
};

export const SAMPLE_CHAR_LIMIT_RE_PATTERN = "^.{1,255}$";

export const DEFAULT_VALUES_FOR_NEW_FIELDS = {
  messageFlow: "Through our clients, we collect and process consumer opt-ins and opt-outs.",
  optoutMessage: "You have been removed from the list for this {Brand Name} survey and will receive no further messages.",
  helpMessage: "This survey is powered by Survey 160 software. For more information, please visit survey160.com"
};

export const SAMPLE_CHAR_LIMIT = 255;
export const SAMPLE_CHAR_MIN = 20;

export const DESCRIPTION_CHAR_MIN = 40;

export const VALIDATION_SCHEMA = Yup.object().shape({
  brandId: Yup.string().required("Brand ID is required"),
  vertical: Yup.string().notRequired(),
  usecase: Yup.string().required("Use case is required"),
  description: Yup.string().min(DESCRIPTION_CHAR_MIN, `Description must be at least ${DESCRIPTION_CHAR_MIN} characters long`).required("Description is required"),
  subUsecases: Yup.string().notRequired(),
  sample1: Yup.string()
    .min(SAMPLE_CHAR_MIN, `Sample message must be at least ${SAMPLE_CHAR_MIN} characters long`)
    .max(SAMPLE_CHAR_LIMIT, "Sample is over 255 characters!")
    .required("Sample 1 is required"),
  sample2: Yup.string().when((val) => {
    if (val.length > 0 && val[0]) {
      //if address exist then apply min max else not
      return Yup.string()
        .min(SAMPLE_CHAR_MIN, `Sample message must be at least ${SAMPLE_CHAR_MIN} characters long`)
        .max(
          SAMPLE_CHAR_LIMIT,
          `Sample must have less than ${SAMPLE_CHAR_LIMIT} characters`
        )
        .required("Required");
    } else {
      return Yup.string().notRequired();
    }
  }),
  sample3: Yup.string().when((val) => {
    if (val.length > 0 && val[0]) {
      //if address exist then apply min max else not
      return Yup.string()
        .min(SAMPLE_CHAR_MIN, `Sample message must be at least ${SAMPLE_CHAR_MIN} characters long`)
        .max(
          SAMPLE_CHAR_LIMIT,
          `Sample must have less than ${SAMPLE_CHAR_LIMIT} characters`
        )
        .required("Required");
    } else {
      return Yup.string().notRequired();
    }
  }),
  sample4: Yup.string().when((val) => {
    if (val.length > 0 && val[0]) {
      //if address exist then apply min max else not
      return Yup.string()
        .min(SAMPLE_CHAR_MIN, `Sample message must be at least ${SAMPLE_CHAR_MIN} characters long`)
        .max(
          SAMPLE_CHAR_LIMIT,
          `Sample must have less than ${SAMPLE_CHAR_LIMIT} characters`
        )
        .required("Required");
    } else {
      return Yup.string().notRequired();
    }
  }),
  sample5: Yup.string().when((val) => {
    if (val.length > 0 && val[0]) {
      console.log(val);
      //if address exist then apply min max else not
      return Yup.string()
        .min(SAMPLE_CHAR_MIN, `Sample message must be at least ${SAMPLE_CHAR_MIN} characters long`)
        .max(
          SAMPLE_CHAR_LIMIT,
          `Sample must have less than ${SAMPLE_CHAR_LIMIT} characters`
        ).required("Sample 5 is Required");
    } else {
      return Yup.string().notRequired();
    }
  }),
  optoutMessage: Yup.string().required("Opt-out message is required"),
  // messageFlow: Yup.string().required("Message flow is required"),
  helpMessage: Yup.string().required("Help message is required"),
  messageFlow: Yup.string().required(
    "Message flow is required"
  ),
  numberPool: Yup.boolean().oneOf([true, false], "Number pool is required"),
  directLending: Yup.boolean().oneOf(
    [true, false],
    "Direct lending is required"
  ),
  embeddedLink: Yup.boolean().oneOf([true, false], "Embedded link is required"),
  embeddedPhone: Yup.boolean().oneOf(
    [true, false],
    "Embedded phone is required"
  ),
  affiliateMarketing: Yup.boolean().oneOf(
    [true, false],
    "Affiliate marketing is required"
  ),
  ageGated: Yup.boolean().oneOf([true, false], "Age gated is required"),
  autoRenewal: Yup.boolean().oneOf([true, false], "Auto-renewal is required"),
  subscriberOptIn: Yup.boolean().oneOf(
    [true, false],
    "Subscriber opt-in is required"
  ),
  subscriberOptOut: Yup.boolean().oneOf(
    [true, false],
    "Subscriber opt-out is required"
  ),
  subscriberHelp: Yup.boolean().oneOf(
    [true, false],
    "Subscriber help is required"
  ),
});