import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {OrganizationDefaults} from "./OrganizationDefaults";

import {
    DEFAULT_CAMPAIGN_DATA
} from "../../util/constants";
import { retrieveCampaignDataDefaultsPost, updateCampaignDataDefaultsPost } from "../../actions/organizations";
import { dollarsToCents, centsToDollars } from "../../util/helper";

class OrganizationDefaultsContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            valid: true,
            changed: false,
            data: {}
        };

        this.setStateItem = this.setStateItem.bind(this);
        this.validate = this.validate.bind(this);
        this.save = this.save.bind(this);
    }

    static getDerivedStateFromProps (props, state) {
        const {data, loading} = props.campaignDefaults;

        if (data && !loading && !state.changed) {
            return {
                data: {...data, spendlimit: centsToDollars(data.spendlimit), refresh: false}
            };
        }

        if (state.refresh) {
            return {
                refresh: false
            }
        }

        if (!data && state.data) {
            return {
                data: DEFAULT_CAMPAIGN_DATA
            };
        }

        return null;
    }

    componentDidMount() {
        this.props.retrieveCampaignDataDefaultsPost(this.props.organization.organizationid)
    }

    componentDidUpdate() {
        if (this.state.refresh && !this.props.campaignDefaults.loading) {
            this.props.retrieveCampaignDataDefaultsPost(this.props.organization.organizationid);
        }
    }

    setStateItem(e) {
        const newState = {...this.state};

        try {
            // Try parsing to number
            newState.data[e.target.name] = parseFloat(e.target.value);
        } catch {
            newState.data[e.target.name] = e.target.value;
        }

        newState.changed = true;

        this.setState(newState);
    }

    validate(e) {
        this.setState({
            valid: true
        });
    }

    save() {
        const spendLimitInCents = dollarsToCents(this.state.data.spendlimit);

        this.props.updateCampaignDataDefaultsPost(this.props.organization.organizationid, {...this.state.data, spendlimit: spendLimitInCents}).then(() => {
            this.setState({
                changed: false,
                refresh: true
            });
        });

    }

    render() {
        return this.state.data ? <OrganizationDefaults
                    data={this.state.data}
                    setStateItem={this.setStateItem}
                    validate={this.validate}
                    valid={this.state.valid}
                    changed={this.state.changed}
                    save={this.save}
                    loading={this.props.updateCampaignDefaults && this.props.updateCampaignDefaults.loading}
                /> : null;
    }
}

const mapStateToProps = state => ({
    campaignDefaults: state.organizations.campaignDefaults,
    updateCampaignDefaults: state.organizations.updateCampaignDefaults
});

const mapDispatchToProps = dispatch =>
bindActionCreators(
    {
        retrieveCampaignDataDefaultsPost: (id) => retrieveCampaignDataDefaultsPost(id),
        updateCampaignDataDefaultsPost: (id, data) => updateCampaignDataDefaultsPost(id, data)
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationDefaultsContainer);