import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import ModalContainer, {
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "../modals/ModalContainer";
import { BulmaButtonProper } from "../generic/Buttons/BulmaButtonProper";

export function LinkRegistrationModal({ loading, onSubmit }) {
  return (
      <ModalContainer
        modalCardClasses="fit-content"
        name={"Link Registration <>"}
        customButtonClass="button"
      >
        {(modalState) => (
          <>
            <ModalHeader>
              <h3>
                <b>Link Existing Registration</b>
              </h3>
            </ModalHeader>
            <ModalBody>
              <LinkRegistrationForm onSubmitRegistration={async (reg_id) => {
                await onSubmit(reg_id);
                modalState.toggleOpen();
              }}/>
            </ModalBody>
            <ModalFooter {...modalState}>
              <BulmaButtonProper disabled={loading} type="submit" form="submit-reg-form">
                Submit
              </BulmaButtonProper>
            </ModalFooter>
          </>
        )}
      </ModalContainer>
  );
}

function LinkRegistrationForm({ disabled = false, onSubmitRegistration }) {
  function onSubmit(values, { setSubmitting }) {
    setSubmitting(true);
    console.log("I am a submit function", values.registration_id);
    try {
      onSubmitRegistration(values.registration_id);
    } catch (err) {
      console.log("Failed in linking new lines for ID:", err);
    }
  }
  return (
    <Formik
      initialValues={initValues}
      enableReinitialize={true}
      validationSchema={VALIDATION_SCHEMA}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, dirty, isValid, errors, values }) => (
        <Form className="form" id="submit-reg-form">
          {/* {console.log(isValid, dirty, isSubmitting, errors, values)} */}
          <div>
            <label htmlFor="registration_id" className="label">
              Registration ID
            </label>
            <Field
              type="text"
              name="registration_id"
              className="input"
              disabled={disabled}
            />
            <ErrorMessage
              name="registration_id"
              component="div"
              className="help is-danger"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
}

const initValues = {
  registration_id: "",
};

const VALIDATION_SCHEMA = Yup.object().shape({
  registration_id: Yup.string().required("Registration ID is required"),
});
