import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ConfirmActionModal from "../modals/ConfirmActionModal";
import { setResponseRateAdjustment } from "../../redux/SelectedCampaign/actions";
import PropTypes from "prop-types";
import { S160BoolCampaignOptions } from "../common/S160BoolCampaignOptions";

const ResponseRateCheckbox = ({
  selectedCampaignData,
  selectedCampaignId,
  useResponseRate,
  setResponseRateAdjustment,
  ...props
}) => {

    return (
  <S160BoolCampaignOptions
      optionDescription="Use response rate adjustment"
      selectedCampaignId={selectedCampaignId}
    // actionName={`${useResponseRate ? "Deactivate" : "Activate"} response rate adjustment`}
    actionCallback={() =>
      setResponseRateAdjustment(!useResponseRate)
    }
    enabled={useResponseRate}
    // {...props}
  />
)};

const mapStateToProps = (state) => {
  const { selectedCampaignData } = state.selectedCampaign.selectedCampaign;
  const { selectedCampaignIndex } = state.NAID;
  return {
    useResponseRate: selectedCampaignData.use_response_rate_adjustment,
    selectedCampaignId: selectedCampaignIndex
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setResponseRateAdjustment
    },
    dispatch
  );

ResponseRateCheckbox.propTypes = {
  selectedCampaignData: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResponseRateCheckbox);
