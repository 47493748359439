import React, { Component } from "react";
import Modal from "react-modal";

class ModalContainer extends Component {
  constructor(props) {
    super(props);

    this.state = { isOpen: false };

    this.toggleOpen = this.toggleOpen.bind(this);
  }

  componentDidMount() {
    if (this.props.open) {
      this.toggleOpen();
    }
  }

  toggleOpen() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    const { customButtonClass, name, title, disabled = null } = this.props;
    const buttonClass = customButtonClass
      ? customButtonClass
      : "button is-info";

    return (
      <React.Fragment>
        <button disabled={disabled} className={buttonClass} onClick={this.toggleOpen} title={title}>
          {name}
        </button>
        <div className="modal-container">
          <Modal
            isOpen={this.state.isOpen}
            className={"modal" + (this.state.isOpen ? " is-active" : "")}
          >
            <div className="modal-background" onClick={this.toggleOpen} />
            <div className="modal-card">
              {/* {this.renderChildrenWithTabsApiAsProps()} */}
              {this.props.children({ toggleOpen: this.toggleOpen })}
            </div>
          </Modal>
        </div>
      </React.Fragment>
    );
  }
}

export const ModalHeader = ({ children, ...props }) => {
  return <header className="modal-card-head">{children}</header>;
};

export const ModalBody = ({ children, ...props }) => {
  return <section className="modal-card-body">{children}</section>;
};

export const ModalFooter = ({ children, toggleOpen, customText="Cancel", ...props }) => {
  return (
    <footer className="modal-card-foot">
      {children}
      {!props.hideCancel && (
        <button className="button is-danger" onClick={toggleOpen}>
          {customText}
        </button>
      )}
    </footer>
  );
};

export default ModalContainer;
