import React from "react";
import PropTypes from "prop-types";

/**
 * A reusable field display component for displaying campaign data fields.
 * uses Bulma field and label to display a value
 *
 * @prop {string} name The displayname for the field
 * @prop {string} value The value of the field
 * @prop {string} name The default fallback value to display if the field is missing or null
 *
 */
export const S160Field = ({ name, value, fallbackValue }) => (
  <div className="field">
    {name}
    <label className="label">{value ? value : fallbackValue}</label>
  </div>
);

S160Field.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  fallbackValue: PropTypes.string,
};
