import React from "react";
import { BulmaButtonProper } from "../generic/Buttons/BulmaButtonProper";

export const ResetTargetsButton = ({setFieldAll}) => (
  <BulmaButtonProper
    title={"Set all strata targets to 0"}
    onClick={() => setFieldAll("target_complete", 0)}
  >
    Targets to 0
  </BulmaButtonProper>
);
