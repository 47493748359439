import React from "react";
/**
 * Important! The page selection is 0 based but should probably be displayed as 1 base.
 * @param {*} param0
 */
export const ChargesPaginationHeader = ({
  currentPage,
  totalPages,
  flipPage
}) => (
  <nav
    className="pagination is-centered is-small"
    role="navigation"
    aria-label="pagination"
  >
    <a className="pagination-previous"  onClick={() => flipPage(currentPage - 1)}>{"<"}</a>
    <a className="pagination-next"  onClick={() => flipPage(currentPage + 1)}>{">"}</a>
    <ul className="pagination-list">
      {currentPage > 1 && <li>
        <a
          className="pagination-link"
          aria-label="Goto page 1"
          onClick={() => flipPage(0)}
        >
          1
        </a>
      </li>}
      {currentPage > 2 && (
        <li>
          <span className="pagination-ellipsis">&hellip;</span>
        </li>
      )}
      {currentPage > 0 && <li>
        <a className="pagination-link" aria-label="Goto page 45"  onClick={() => flipPage(currentPage - 1)}>
          {currentPage}
        </a>
      </li>}
      <li>
        <a
          className="pagination-link is-current"
          aria-label="Goto page 45"
          aria-current="page"
        >
          {currentPage + 1}
        </a>
      </li>
      {currentPage + 2 < totalPages && <li>
        <a className="pagination-link" aria-label="Goto page 45" onClick={() => flipPage(currentPage + 1)}>
          {currentPage + 2}
        </a>
      </li>}

      {currentPage + 1 < totalPages - 1 && (
        <li>
          <span className="pagination-ellipsis">&hellip;</span>
        </li>
      )}
      {currentPage + 1 !== totalPages && <li>
        <a className="pagination-link" aria-label="Goto page 86" onClick={() => flipPage(totalPages - 1)}>
          {totalPages}
        </a>
      </li>}
    </ul>
  </nav>
);
