import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setUseWebCompletes } from "../../redux/SelectedCampaign/actions";
import { S160BoolCampaignOptions } from "../common/S160BoolCampaignOptions";


const UseWebCompletesModal = ({ selectedCampaignId, disabled, useWebCompletes, setUseWebCompletes }) => (
  <S160BoolCampaignOptions
    optionDescription={`Use web completes`}
    selectedCampaignId={selectedCampaignId}
    actionCallback={() => {
      if (disabled) {
        console.log("No quotas found");
        return;
      }
      setUseWebCompletes(!useWebCompletes);
    }}
    enabled={useWebCompletes}
  />
);

const mapStateToProps = (state) => {
  const { selectedCampaignData } = state.selectedCampaign.selectedCampaign;
  const { selectedCampaignIndex } = state.NAID;
  return {
    useWebCompletes: selectedCampaignData.use_web_completes,
    selectedCampaignId: selectedCampaignIndex
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { setUseWebCompletes },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UseWebCompletesModal);
