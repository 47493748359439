import React from "react";
import { MediaSelectDropdown } from "./MediaSelect";
import { connect } from "react-redux";

const ConnectedMediaSelect = (props) => {
  const { error, loading, data } = props.media;

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error!</div>;
  }

  return <MediaSelectDropdown {...props} mediaObject={data} />;
};

const mapStateToProps = (state) => ({
  media: state.media,
});

export default connect(mapStateToProps)(ConnectedMediaSelect);
