import { fetchRequest } from "../../middleware/fetchMiddleware";

export const OPTOUT_EXPORT = "OPTOUT_EXPORT";
export const SET_ADD_ERR_MSG = "SET_ADD_TO_OPTOUT_ERROR_MESSAGE";
const OPTOUT_ADD_NUMBER = "OPTOUT_ADD_NUMBER";

/**
 * Start exporting the optout list file to cloud storage
 * @returns {Promise} the request promise is passed down
 */
export function startOptoutExport() {
  return (dispatch) => {
    return dispatch(
      fetchRequest(OPTOUT_EXPORT, "POST", "/startOptoutExport", {})
    );
  };
}

/**
 * Once export is finished, this allows you to download file
 * @returns {Promise} the request promise is passed down
 */
export function getOptoutDownloadURL() {
  return (dispatch, getState) => {
    return dispatch(
      fetchRequest(
        "GET_OPTOUT_SIGNED_URL",
        "POST",
        "/getOptoutDownloadURL",
        {}
      )
    )
      .then((data) => {
        window.open(data.url);
      });
  };
}


/**
 * Adds a number to the optout list
 * @returns {Promise} the request promise is passed down
 */
export function addPhoneNumberToOptoutList(phoneNumber) {
  return (dispatch, getState) => {
    return dispatch(
      fetchRequest(
        OPTOUT_ADD_NUMBER,
        "POST",
        "/optout/addNumber",
        {
          phoneNumber
        }
      )
    );
  };
}

/**
 * Dispatches an action of type SET_ADD_ERR_MSG with a error message (key is errMsg)
 * @param {*} message The error message to check
 * @returns Promise
 */
export function setAddToErrorMessage (message) {
  return (dispatch) => {
    return dispatch({
      type: SET_ADD_ERR_MSG,
      msg: message
    })
  }
} 