import React from "react";

export const TopBarDropdownMenu = ({title, children}) => (
  <div className="navbar-item has-dropdown is-hoverable">
    <a className="navbar-link">{title}</a>
    <div className="navbar-dropdown is-boxed is-right">
        {children}
    </div>
  </div>
);
