import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const style = {
  margin: "1em",
};
export const SuccessCheck = () => (
  <span className="has-text-success" style={style}>
    <FontAwesomeIcon icon={faCheck} />
  </span>
);
