const PANEL_OPT_IN_QUESTION_TEXT =
  "Are you willing to answer future surveys by text message? Reply YES to receive future polling and survey msgs from us and our partners. Txt STOP to stop and HELP for help. Msg. and data rates may apply.";
const PANEL_ACCEPT_QUESTION_TEXT =
  "Thank you for signing up to participate in future text message surveys! Please see survey160.com/privacy for the terms/privacy policy that will apply to such surveys. You can opt out at survey160.com/optout";
const PANEL_REFUSE_QUESTION_TEXT =
  "Thank you for your participation in this survey.";
const INTRO_QUESTION_TEXT =
  "Survey160 is conducting a public opinion survey. Would you like to participate? \n Reply YES to start, NO to decline.";

export const QUESTION_TYPES = [
  { value: "intro", text: "Intro Opt-In" },
  { value: "text", text: "Open-ended" },
  { value: "closed", text: "Close-ended" },
  { value: "closing", text: "Closing" },
  { value: "terminating", text: "Terminating" },
];

export const ADMIN_QUESTION_TYPES = [
  { value: "panel", text: "Panel Opt-In" },
  { value: "panel_close", text: "Panel Closing" },
];

export const getScriptQuestionObject = (
  id,
  type,
  question = "",
  numberOfAnswers = 0,
  numberOfConditionals = 0,
  defaultNext
) => ({
  id: id,
  type: type,
  question: question,
  answers: [
    {
      value: -1,
      text: "[refused to answer]",
    },
    ...(numberOfAnswers ? createAnswers(numberOfAnswers) : []),
  ],
  conditionalNexts: numberOfConditionals
    ? createConditionalNexts(numberOfConditionals)
    : [],
  defaultNext: defaultNext,
});

function createAnswers(amount) {
  return new Array(amount).map((item, i) => ({ value: i, text: "" }));
}

function createConditionalNexts(amount) {
  return new Array(amount).map((item, i) => ({ value: i, text: "" }));
}

export function createDefaultPanelQuestions() {
  const panel_opt_in = getScriptQuestionObject(
    "panel_opt_in",
    "panel",
    PANEL_OPT_IN_QUESTION_TEXT,
    0,
    2
  );
  panel_opt_in.answers = [{ value: 1, text: "No" }, { value: 2, text: "Yes" }];
  panel_opt_in.conditionalNexts = [
    { condition: "[panel_opt_in] == 2", goto: "panel_close" },
    { condition: "[panel_opt_in] == 1", goto: "panel_refuse" },
  ];

  const panel_accept = getScriptQuestionObject(
    "panel_close",
    "panel_close",
    PANEL_ACCEPT_QUESTION_TEXT
  );
  const panel_refuse = getScriptQuestionObject(
    "panel_refuse",
    "panel_close",
    PANEL_REFUSE_QUESTION_TEXT
  );
  return { panel_accept, panel_refuse, panel_opt_in };
}

export function createDefaultIntroQUestion() {
  const intro = getScriptQuestionObject(
    "intro",
    "intro",
    INTRO_QUESTION_TEXT,
    0,
    0
  );

  intro.answers = [
    { value: 1, text: "No" },
    { value: 2, text: "Yes" },
    { value: 3, text: "Not Applicable" },
    { value: 4, text: "Permanent Opt Out" },
  ];

  return intro;
}

export function createDefaultEmptyQuestion(i, script, question_type) {
  /**
   * Checks the script ids and returns a valid unique id that isn't included in it
   * @returns the valid id
   */
  let getValidQuestionId = () => {
    let name = `q_${Math.ceil(i)}`;

    while (
      script.map((item) => item.id).filter((id) => id === name).length > 0
    ) {
      name += "_copy";
    }

    return name;
  };

  return getScriptQuestionObject(
    getValidQuestionId(),
    question_type,
    "",
    0,
    0,
    script[i + 1] ? script[i + 1].id : undefined
  );
}
