import { fetchRequest } from "../../middleware/fetchMiddleware";
import { userRequestedDownload } from "../../actions/vitals";

export const addNotification = notification => {
  return dispatch => dispatch({ type: "ADD_NOTIFICATION", notification });
};

export const clearNotification = notification => {
  return dispatch =>
    dispatch({
      type: "CLEAR_NOTIFICATION",
      notification: { ...notification, visible: false }
    });
};

export function startCampaignResultsDownload(campaignid, filterOpen) {
  return (dispatch, getState) => {
    const userid = getState().auth.userid;
    return dispatch(
      fetchRequest("START_EXPORT", "POST", "/startCampaignResultsExport", {
        campaignid,
        userid,
        filterOpen,
      })
    ).then(data => {
      if (data.status === "ready") {
        window.open(data.url);
      }

      if (data.status === "processing") {
        // Update user profile to include request for file
        // The backend will update the value in the DB.
        dispatch(userRequestedDownload(campaignid));
      }
    });
  };
}

export function getUrlAndDownloadFile(campaignid) {
  return (dispatch, getState) => {
    return dispatch(
      fetchRequest("GET_SIGNED_URL", "POST", "/downloadFileFromBucket", {
        campaignid
      })
    ).then(data => {
      window.open(data.url);

      let notification = getState().globalNotifications.notifications.find(
        item => item.campaignid === campaignid
      );

      if (notification) {
        dispatch(clearNotification(notification));
      }
    })
    .catch(e => console.error(e));
  };
}

export function startTextToWebResultsDownload() {
  return (dispatch, getState) => {
    return dispatch(
      fetchRequest("START_T2W_EXPORT", "POST", "/startTextToWebExport", {})
    );
  };
}

export function getT2WUrlAndDownloadFile() {
  return (dispatch, getState) => {
    return dispatch(
      fetchRequest("GET_T2W_SIGNED_URL", "POST", "/downloadTextToWebFileFromBucket", {})
    ).then(data => {
      window.open(data.url);
    }) 
    .catch(e => console.error(e));
  };
}
