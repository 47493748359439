import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import AddCampaignModal from "../modals/AddCampaignModal";
import { statusMap } from "./constants";
import { SidebarElementHeader } from "./SidebarElementHeader";
import { NavLinkListItemWrapper } from "./NavLinkListItemWrapper";

export const Sidebar = ({
  campaignsByOrganization,
  vitalsData,
  match,
  auth,
  history,
  children,
  showSearchResults,
  campaigns,
  singleOrgPage,
  showAddCampaignButton,
  showOrganizationSelect,
}) => {
  return (
    <ul className="menu-list">
      <li key='top-section'>
          {children}
      </li>
      {!showSearchResults && campaignsByOrganization.map((org, i) => (
        <SidebarElementHeader
          key={i}
          name={org.organizationname}
          link={match.url + "/organizations/" + org.organizationid + "/"}
          disableLink={!singleOrgPage}
          uniqueId={org.organizationid}
        >
          <>
          {org.campaigns.map((campaign) => (
            <NavLinkListItemWrapper
              to={match.url + "/campaigns/" + campaign.campaignid + "/"}
              key={"c-" + campaign.campaignid}
            >
              <span className={"has-text-" + statusMap[campaign.active].color}>
                <FontAwesomeIcon
                  icon={statusMap[campaign.active].icon}
                  size="xs"
                />
              </span>
              &nbsp;
              {campaign.name}
              <span className="is-pulled-right">({campaign.campaignid})</span>
            </NavLinkListItemWrapper>
          ))}
          {showAddCampaignButton && <AddCampaignModal
            key={"add-campaign-org-" + org.organizationid}
            history={history}
            organizationid={org.organizationid}
            organizationArray={vitalsData.organizationarray}
            showOrganizationSelect={showOrganizationSelect}
          />}
          </>
        </SidebarElementHeader>
     
      ))}
         
      {showSearchResults && campaigns.map((campaign) => (
      <NavLinkListItemWrapper
          to={match.url + "/campaigns/" + campaign.campaignid + "/"}
          key={"c-" + campaign.campaignid}
      >
          <span className={"has-text-" + statusMap[campaign.active].color}>
          <FontAwesomeIcon
              icon={statusMap[campaign.active].icon}
              size="xs"
          />
          </span>
          &nbsp;
          {campaign.name}
          <span className="is-pulled-right">({campaign.campaignid})</span>
      </NavLinkListItemWrapper>
      ))}
    </ul>
  );
};


