import React from "react";
import TextToolsModal from "./TextToolsModal";

export const TextTools = ({ campaignid, recipientbatchsize, actionablethreshold, loading }) => {
  return (
    <div className="tile is-parent is-vertical">
      <h4 className="title is-4 has-text-centered">TEXT TOOLS</h4>
      <div className="tile is-parent">
        <div className="tile is-child">
          <div className="field">
            Recipient Batch Size
            <label className="label"> {recipientbatchsize}</label>
          </div>
          <div className="field">
           Threshold for loading more conversations
            <label className="label"> {actionablethreshold}</label>
          </div>
        </div>
        <div className="tile is-child is-right">
          <TextToolsModal campaignid={campaignid} recipientbatchsize={recipientbatchsize} actionablethreshold={actionablethreshold}/>
        </div>
      </div>
    </div>
  );
};
