import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ModalContainer, {
  ModalBody,
  ModalHeader,
  ModalFooter
} from "./ModalContainer";

import { updateUserPermissions } from "../../redux/Users/actions";

import { USER_ROLES_LOOKUP_TABLE } from "../../util/constants";

class ChangeUserPermissionsModal extends Component {
  render() {
    const {
      userid,
      updateUserPermissions,
      loading,
      userDataUpdated,
      roleOptions,
      userRole
    } = this.props;

    var lookup = USER_ROLES_LOOKUP_TABLE;
    // A superuser can create any role.
    // Organization Admins and Campaign Admins can only create lesser roles.

    return (
      <ModalContainer name={userRole} customButtonClass="button">
        {modalState => (
          <React.Fragment>
            <ModalHeader>Change User Permissions</ModalHeader>
            <ModalBody>
              <div className="field">
                <label className="label">Change user role: </label>
                <p className="control">
                  <div className="select">
                    <select ref={input => (this.selectedRole = input)}>
                      {roleOptions}
                    </select>
                  </div>
                </p>
              </div>
            </ModalBody>
            <ModalFooter {...modalState}>
              <a
                className={"button is-info" + (loading ? " is-loading" : "")}
                onClick={() => {
                  updateUserPermissions(userid, this.selectedRole.value);
                  modalState.toggleOpen();
                  userDataUpdated();
                }}
              >
                Confirm
              </a>
            </ModalFooter>
          </React.Fragment>
        )}
      </ModalContainer>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateUserPermissions: (userid, permission) =>
        updateUserPermissions(userid, permission)
    },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(ChangeUserPermissionsModal);
