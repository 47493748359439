import {
  EDIT_USER,
  USERS_IS_LOADING,
  USERS_HAS_ERROR,
  USERS_POST_SUCCESS,
  ADD_USER_AUTHY_PREFIX,
} from "./actions";
import { USER_ROLES_LOOKUP_TABLE } from "../../util/constants";
import { combineReducers } from "redux";
import {
  createAddErrorMsgReducer,
  loadStateReducer,
} from "../../util/reusableReducers";
import reduceReducers from "reduce-reducers";

const initialState = {
  loading: false,
  error: false,
  users: [],
};

const mainReducer = (state = initialState, action) => {
  switch (action.type) {
    case USERS_IS_LOADING:
      return {
        ...state,
        loading: action.isLoading,
      };
    case USERS_HAS_ERROR:
      return {
        ...state,
        error: action.hasError,
        loading: false,
      };
    case USERS_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.data.data.map((item) => {
          return {
            ...item,
            permission: USER_ROLES_LOOKUP_TABLE[item.permissionlevel],
          };
        }),
      };
    case "CLEAR_DATA":
      return initialState;
    default:
      return state;
  }
};

const deleteUser = loadStateReducer({
  DELETE_USER_IS_LOADING: "loading",
  DELETE_USER_HAS_ERROR: "error",
  DELETE_USER_POST_SUCCESS: "success",
});

const updateUserPermissions = loadStateReducer({
  UPDATE_USER_PERMISSIONS_IS_LOADING: "loading",
  UPDATE_USER_PERMISSIONS_HAS_ERROR: "error",
  UPDATE_USER_PERMISSIONS_POST_SUCCESS: "success",
});

const updateUserAnalyticsCheckboxConfig = loadStateReducer({
  UPDATE_USER_ANALYTICSCHECKBOXCONFIG_IS_LOADING: "loading",
  UPDATE_USER_ANALYTICSCHECKBOXCONFIG_HAS_ERROR: "error",
  UPDATE_USER_ANALYTICSCHECKBOXCONFIG_POST_SUCCESS: "success",
});

const addUserToCampaign = loadStateReducer({
  ADD_USER_TO_CAMPAIGN_IS_LOADING: "loading",
  ADD_USER_TO_CAMPAIGN_HAS_ERROR: "error",
  ADD_USER_TO_CAMPAIGN_POST_SUCCESS: "success",
});

const removeUserFromCampaign = loadStateReducer({
  REMOVE_USER_FROM_CAMPAIGN_IS_LOADING: "loading",
  REMOVE_USER_FROM_CAMPAIGN_HAS_ERROR: "error",
  REMOVE_USER_FROM_CAMPAIGN_POST_SUCCESS: "success",
});

const addUserAuthy = reduceReducers(
  loadStateReducer({
    [ADD_USER_AUTHY_PREFIX + "_IS_LOADING"]: "loading",
    [ADD_USER_AUTHY_PREFIX + "_HAS_ERROR"]: "error",
    [ADD_USER_AUTHY_PREFIX + "_POST_SUCCESS"]: "success",
  }),
  // This adds in the error msg part
  createAddErrorMsgReducer(ADD_USER_AUTHY_PREFIX)
);

export default combineReducers({
  userData: mainReducer,
  addUserAuthy,
  deleteUser,
  updateUserPermissions,
  addUserToCampaign,
  removeUserFromCampaign,
  updateUserAnalyticsCheckboxConfig,
});
