import React from "react";

export const LaunchApproval = ({ active, launchCampaign }) => (
  <a
    className={`button is-success is-fullwidth ${!active && "is-static"}`}
    onClick={launchCampaign}
  >
    Approve Campaign Launch
  </a>
);
