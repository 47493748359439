import React from "react";

import { retrieveWarnings } from "../../redux/SelectedCampaign/actions";
import {withAsyncCall} from "../higher-order/AsyncWrapper";

const WarningsContainer = ({warnings, children, ...props}) => {
    console.log(warnings)
    const newChildren = React.Children.map(children, c => React.cloneElement(c, {
        ...props, warnings: warnings}));

    return newChildren;
}

const mapStateToProps = state => ({
    warnings: state.selectedCampaign.warnings
  });

const asyncMethod = (campaignid) => dispatch => {
    dispatch(retrieveWarnings(campaignid))
};

  export default withAsyncCall(WarningsContainer, asyncMethod, mapStateToProps);