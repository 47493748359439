import React from "react";
import { BulmaButton } from "../generic/Buttons/BulmaButton";
import OptoutAddNumberContainer from "../OptoutAddNumber/OptoutAddNumberContainer";

export const Optout = ({ error, loading, data, startOptoutExport }) => (
  <div>
    <h3 className="title has-text-centered">Permanent Optout List Tools</h3>

    <OptoutAddNumberContainer />

    <hr />

    <h3 className="title is-4">Tools</h3>
    <BulmaButton customClass="is-info" onClick={startOptoutExport}>
      Export full optout list
    </BulmaButton>
  </div>
);
