import React, { Component } from "react";
import { SERVER_URLROOT} from "../../util/constants";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import io from "socket.io-client";
import { addNotification } from "../../redux/GlobalNotifications/actions";
import { setProcessingResultsChanged } from "../../redux/Recipients/actions";
import { getDownloadNotificationTemplate } from "../GlobalNotifications/util";

class SocketHandlerContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      connected: false,
    };

    this.handleNotification = this.handleNotification.bind(this);
  }

  componentDidMount() {
    this.socketSetup();
  }

  socketSetup = () => {
    this.socket = io(SERVER_URLROOT, {
      "force new connection": false,
      transports: ["websocket"]
    });

    this.socket.on("connect", () => {
      this.socket.emit("joinroom", {
        userid: this.props.userid,
        twilioroom: "setup_room", // For now we'll have a single room for all admins, since they are few
      });
    });

    this.socket.on("notification", this.handleNotification);

    this.socket.on("connection_error", (err) => {
      console.group("Socket Connection error");
      console.error("code", err.code);
      console.error("message", err.message);
      console.error("context", err.context);
      console.groupEnd();
    });

    this.socket.on("disconnect", (reason, details) => {
      console.group("Socket Disconnected");
      
      // the reason of the disconnection, for example "transport error"
      console.log(reason);
      
      // the low-level reason of the disconnection, for example "xhr post error"
      // console.log(details.message);
      
      // some additional description, for example the status code of the HTTP response
      // console.log(details.description);
      
      // some additional context, for example the XMLHttpRequest object
      // console.log(details.context);
      console.groupEnd();
    });
  };

  handleNotification = (notification) => {
    let campaignid;
    const requestedDownload = this.props.vitals.data.requestedDownload;
    try {
      campaignid = parseInt(notification.campaignid);
    } catch (error) {
      return;
    }
    switch (notification.type) {
      case "download":
        if (requestedDownload.includes(campaignid)) {
          this.props.addNotification(
            getDownloadNotificationTemplate(campaignid)
          );
        }
        break;
      case "t2w_download":
        this.props.addNotification({
          type: "T2W_EXPORT",
          visible: true
        });
        break;
      case "optout_download":
        this.props.addNotification({
          type: "OPTOUT_EXPORT",
          visible: true
        });
        break;
      case "transfer_lines":
        this.props.addNotification({
          type: "OUTGOING_LINES_TRANSFER",
          visible: true,
          campaignid,
          message: notification.message,
        });
        break;
      case "upload":
        // This is really only necessary if you are already on the page. In that case, this causes
        // a reload of the results and the component should show the updated state.
        this.props.setProcessingResultsChanged(true);
        this.props.addNotification({
          type: "UPLOAD",
          status: notification.status,
          campaignid,
          visible: true,
        });
        break;
      default:
        break;
    }
  };

  render = () => null;
}

const mapStateToProps = (state) => ({
  // userSettings: state.vitals
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addNotification: (notification) => addNotification(notification),
      setProcessingResultsChanged: (value) =>
        setProcessingResultsChanged(value),
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SocketHandlerContainer);
