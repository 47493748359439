import React from "react";
import { QuestionWrapper } from "./QuestionWrapper";
import { ConditionalTable } from "./ConditionalTable";

export const OpenEnded = (props) => {
  return (
    <QuestionWrapper {...props}>
      <ConditionalTable {...props} />
    </QuestionWrapper>
  );
};
