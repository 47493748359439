import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { addPhoneNumberToOptoutList } from "../../redux/OptoutList/actions";
import CustomForm, { txtFieldState } from "../generic/CustomForm";
import { OptoutAddNumberForm } from "./OptoutAddNumberForm";

const initFormState = {
  phone: {
    ...txtFieldState,
    fieldName: "phone",
    required: true,
    requiredTxt: "Phone is required.",
    formatErrorTxt: "Format +X-XXX-XXX-XXXX",
  },
  allFieldsValid: true,
};

const OptoutAddNumberContainer = ({addPhoneNumberToOptoutList, getOptoutListExport, addToOptoutList}) => {
  const { errMsg, loading, data } = addToOptoutList;
  const success = data !== null && data !== undefined;
  return(
  <CustomForm
    initState={initFormState}
    submit={(formElements) => addPhoneNumberToOptoutList(formElements.phone.value)}
    render={(formState) => (
      <>
        <OptoutAddNumberForm {...formState} errMsg={errMsg} loading={loading} success={success}/>
        <button className="button is-success" type="submit">Add</button>
      </>
    )}
  />
)};

const mapStateToProps = (state) => ({
  ...state.optoutList
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ addPhoneNumberToOptoutList }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(OptoutAddNumberContainer);
