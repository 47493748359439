import React, {Component} from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import ConfirmActionModal from "./ConfirmActionModal";

import { deleteCampaignPost } from "../../actions/NAID";

class DeleteCampaignModal extends Component {
    render () {
        return (
            <ConfirmActionModal
              actionCallback={() => {
                this.props.deleteCampaign();
              }}
              actionName="delete campaign"
              itemName={this.props.campaignName}
              {...this.props}
            />
        );
    }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
    deleteCampaign: (history) =>
        deleteCampaignPost(history)
    },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(DeleteCampaignModal);