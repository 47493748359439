import React from "react";
import List from "../List";

export const StrataData = ({ headers, list, totals }) => (
  <>
    <List headers={headers} list={list} />
    <label className="label">Totals </label>
    <table className="table" style={{ overflow: "auto" }}>
    <tr>
    <td>
          <label className="label">Tgt. Completes</label>
        </td>
        <td>
          <label className="label">Cur. Completes</label>
        </td>
        <td>
          <label className="label">Tgt. Proportion</label>
        </td>
        <td>
          <label className="label">Available</label>
        </td>
        <td>
          <label className="label">Assigned</label>
        </td>
        <td>
          <label className="label">Started</label>
        </td>
        <td>
          <label className="label">Engagend</label>
        </td>
        <td>
          <label className="label">Terminated</label>
        </td>
        <td>
          <label className="label">Total</label>
        </td>
    </tr>
      <tr>
        <td>
          {totals.target_complete}
        </td>
        <td>
          {totals.closed}
        </td>
        <td>
          {totals.assignment_weight}
        </td>
        <td>
          {totals.available}
        </td>
        <td>
          {totals.assigned}
        </td>
        <td>
          {totals.started}
        </td>
        <td>
          {totals.engaged}
        </td>
        <td>
          {totals.terminated}
        </td>
        <td>
          {totals.total}
        </td>
      </tr>
    </table>
  </>
);
