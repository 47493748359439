import React, { useEffect } from 'react';
import ModalContainerFree, { ModalHeader, ModalBody, ModalFooter } from '../modals/ModalContainerFree';
import BandwidthRegistrationFormWithBrands from '../BWRegistrationTab/BandwidthRegistrationFormWithBrands';

export default function EditRegistrationModal(props) {
    const { isOpen, toggleModal, bwSettings = null, onSubmit, getBandwidthRegistrationById, registrationData = null, isNewRegistration = false } = props;

    useEffect(
        () => {
            if (bwSettings && bwSettings.settings_id) {
                getBandwidthRegistrationById(bwSettings.settings_id);
            }
        },
        [bwSettings]
    );

    return (
        <section id='edit-registration-modal' className=''>
            <ModalContainerFree open={isOpen} toggleModal={toggleModal} modalCardClasses="fit-content">
                {(modalState) => (
                    <>
                        <ModalHeader><h3>
                            { isNewRegistration || !bwSettings ?
                                <b>New Registration</b>
                                :
                                <><b>Edit Registration:</b> {bwSettings.registration_id}</>
                            }
                            </h3></ModalHeader>
                        <ModalBody>
                            <BandwidthRegistrationFormWithBrands
                                submitRegistration={onSubmit}
                                registrationData={isNewRegistration ? null : registrationData}
                                disabled={false}
                            />
                        </ModalBody>
                        <ModalFooter {...modalState} />
                    </>
                )}
            </ModalContainerFree>
        </section>
    );
}