import React from "react";

export const Notification = ({children, notifType="info", clear, className}) => (
    <div className={`s160-notif notification ${className}`}>
        <button className="delete" onClick={clear} />
        <div className="notif-content">
            {children}
        </ div>
    </div>
);

