import { globalHTTPResponseHandler } from "../redux/FetchResponseManager/actions";
import { SERVER_URLROOT } from "../util/constants";

/**
 * Dispatch to create a fetch
 */
export function fetchRequest(name, method, url, params) {
  return {  
    type: "FETCH",
    name: name,
    method: method,
    url: url,
    params: params
  };
};

export const fetchMiddleware = fetchImplementation => store => next => action => {
  if (action.type === "FETCH") {
    const { params, url, name, method } = action;

    const myHeaders = new Headers();
    const dispatch = store.dispatch;

    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("accept", "application/json");
    myHeaders.append("Authorization", store.getState().auth.jwt)

    const init = {
      method: method,
      headers: myHeaders,
      body: method == 'GET'? null : JSON.stringify(params)
    };

    dispatch({
        type: name + "_IS_LOADING"
    });

    return fetchImplementation(SERVER_URLROOT + url, init)
      .then(response => {
        dispatch(globalHTTPResponseHandler(response, name));
        
        if (!response.ok) {
          dispatch({
              type: name + "_HAS_ERROR",
              status: response.status,
              message: response.statusText
          });
          throw Error(response.statusText);
        }
        return response;
      })
      .then(response => response.json())
      .then(data => {
        dispatch({
            type: name + "_" + method + "_SUCCESS",
            data: data
        });
        return data;
      })
      .catch(error => {
        console.error(error);
         dispatch({
              type: name + "_HAS_ERROR",
              requestName: name,
              message: error.message
          });
      });
  }
  return next(action);
};
