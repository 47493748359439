import { push } from "connected-react-router";

import { authHasError } from "../../redux/Auth/actions";
import { removeCookieList } from "../../util/helper";
import { setAddToErrorMessage } from "../OptoutList/actions";

export function globalHTTPResponseHandler(response, name) {
  return (dispatch, getState) => {

    // Response is positive so do nothing and just return
    if (response.ok) {
      return;
    }

    switch (response.status) {
      case 401:
        // unauthorized
        removeCookieList(["jwt", "userid", "permission"]);
        dispatch({ type: "CLEAR_DATA" });
        dispatch(push("/authy-login"));
        dispatch(authHasError(true, "You are not logged in."));
        break;
      case 400: // not found
        response.json().then(data => {
          if (!data.message) { return; }
          dispatch(fetchErrorMessage(name, data.message));
          // dispatch(setAddToErrorMessage(data.message));
        })
        break;
      case 403: // not allowed
        response.json().then(data => {
          if (!data.message) { return; }
          dispatch(fetchErrorMessage(name, data.message));
          // dispatch(setAddToErrorMessage(data.message));
        })
      case 404: // Not found
      case 500: // internal server error
      default:
        return;
    }

    // throw Error("Request failed.");
  };
}

export const FETCH_ADD_ERROR_MSG = "FETCH_ADD_ERROR_MSG";
export const FETCH_CLEAR_ERROR_MESSAGE = "FETCH_CLEAR_ERROR_MESSAGE";

export function fetchErrorMessage (requestName, message) {
  return (dispatch) => {
    return dispatch({
      type: FETCH_ADD_ERROR_MSG,
      requestName,
      msg: message
    })
  }
} 

export function clearErrorMsg(requestName) {
  return (dispatch) => {
    return dispatch(
      {
        type: FETCH_CLEAR_ERROR_MESSAGE,
        requestName
      }
    )
  }
}