import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {pauseCampaign, resumeCampaign} from "../../redux/SelectedCampaign/actions";
import ConfirmActionModal from "./ConfirmActionModal";

class PauseResumeCampaignModal extends Component {

    render () {
        switch (this.props.status) {
          case "active":
            return (
              <ConfirmActionModal
                  itemName={this.props.name}
                  actionName="Pause Campaign"
                  actionCallback={() => this.props.pauseCampaign(this.props.campaignid, this.props.subtwiliosid)}
                  buttonClass="button is-warning is-fullwidth"
                />);
          case "paused":
            return (
              <ConfirmActionModal
                  itemName={this.props.name}
                  actionName="Resume Campaign"
                  actionCallback={() => this.props.resumeCampaign(this.props.campaignid)}
                  buttonClass="button is-warning is-fullwidth"
                />);
          default:
              return null;
        }
    }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      pauseCampaign: (campaignid, subtwiliosid) => pauseCampaign(campaignid, subtwiliosid),
      resumeCampaign: campaignid => resumeCampaign(campaignid)
    },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(PauseResumeCampaignModal);