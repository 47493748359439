import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Notification } from '../Notification';
import { NavLink } from "react-router-dom";

class OutgoingLinesTransfer extends Component {
    render() {
        const { campaignid, campaigns, message } = this.props;
        const campaign = campaigns.find(c => c.campaignid === campaignid);

        return <Notification {...this.props}>
            Outgoing lines transfer for campaign <b>{campaign.name}</b> is done.

            <NavLink to="/dashboard/admin/twilio">Outgoing Lines Management</NavLink>
            <br />
            {message}            
        </ Notification>;
    }
}

const mapStateToProps = state => ({
    campaigns: state.NAID.campaigns
});

export default connect(
    mapStateToProps,
)(OutgoingLinesTransfer);