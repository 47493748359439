import React, { Component } from "react";

import ModalContainerFree, {
  ModalBody,
  ModalHeader,
  ModalFooter
} from "./ModalContainerFree";

class ConfirmDeleteUserModal extends Component {
  constructor(props) {
    super(props);

    this.actionWrapper = this.actionWrapper.bind(this);
    this.promiseWrapper = this.promiseWrapper.bind(this);
  }

  actionWrapper(e) {
    this.props.actionCallback();
  }

  promiseWrapper(conditionCallback, timeoutMS, intervalMS) {
    let timeout = timeoutMS;
    return new Promise((resolve, reject) => {
      const checkCondition = () => {
        //console.log("in the promise", conditionCallback());
        if (conditionCallback()) {
          resolve();
        } else if ((timeout -= intervalMS) <= 0) {
          reject();
        } else {
          setTimeout(checkCondition, intervalMS);
        }
      };

      setTimeout(checkCondition, intervalMS);
    });
  }

  render() {
    const { buttonClass, campaignName, actionName } = this.props;

    // If there is a loading prop use that.
    return (
        <ModalContainerFree customButtonClass={buttonClass} open={this.props.open} toggleModal={this.props.toggleModal}>
        {modalState => (
            <React.Fragment>
            <ModalHeader>{actionName}</ModalHeader>
            <ModalBody>
            Are you sure you want to delete <b>{this.props.userID}</b>?
            </ModalBody>
            <ModalFooter {...modalState}>
            <a className={"button is-info "} onClick={this.actionWrapper}>
            {actionName}
            </a>
            </ModalFooter>
            </React.Fragment>
        )}
      </ModalContainerFree>
    );
  }
}

export default ConfirmDeleteUserModal;
