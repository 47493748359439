import { combineReducers } from "redux";
import reduceReducers from "reduce-reducers";

import { createAddErrorMsgReducer, loadStateReducer } from "../../util/reusableReducers";

const getOptoutListExport = loadStateReducer({
  OPTOUT_EXPORT_IS_LOADING: "loading",
  OPTOUT_EXPORT_HAS_ERROR: "error",
  OPTOUT_EXPORT_GET_SUCCESS: "success",
});

const addToOptoutList = loadStateReducer({
  OPTOUT_ADD_NUMBER_IS_LOADING: "loading",
  OPTOUT_ADD_NUMBER_HAS_ERROR: "error",
  OPTOUT_ADD_NUMBER_POST_SUCCESS: "success",
});


const optoutErrorReducer = createAddErrorMsgReducer("OPTOUT_ADD_NUMBER");
const finalAddReducer = reduceReducers(addToOptoutList, optoutErrorReducer);

export default combineReducers({
  getOptoutListExport,
  addToOptoutList: finalAddReducer,
});
