import React from "react";

import {
    PlusButtonCircle,
    PlusButton,
    MinusButton,
    TimesCircleButton,
    TimesButton,
    UpButton,
    DownButton
  } from "../generic/RoundButtons/RoundButtons";

export const QuestionConditionals = ({
    handleConditionalChange,
    handleDefaultNextChange,
    addConditional,
    deleteConditional,
    getQuestionsAfter,
    conditionalNexts,
    qIndex,
    warnings,
    defaultNext,
    validated,
    id    
}) => (
        <table className="flow-logic">
            <thead>
                <tr>
                    <td className="value">Conditional</td>
                    <td className="goto">GOTO</td>
                </tr>
            </thead>
            <tbody>
                {conditionalNexts.map((logic, cIndex) => (
                    <tr key={`row-${cIndex}`}>
                        <td className="conditional">
                            <input
                                name="condition"
                                type="text"
                                rows="1"
                                className="input"
                                value={logic.condition}
                                onChange={e =>
                                    handleConditionalChange(qIndex, cIndex, e)
                                }
                            />
                            {warnings && validated && (
                                <p className="help is-danger">
                                    {warnings.localScript[qIndex].conditionalNexts[cIndex].condition
                                    }
                                </p>
                            )}
                        </td>
                        <td className="goto">
                            <select
                                name="goto"
                                className="dropdown"
                                value={logic.goto}
                                onChange={e =>
                                    handleConditionalChange(qIndex, cIndex, e)
                                }
                            >
                                {getQuestionsAfter(qIndex).map((q, qid) => (
                                    <option key={`goto-${cIndex}-${qid}`} value={q.id}>
                                        {q.id}
                                    </option>
                                ))}
                            </select>
                        </td>
                        <td className="action-button">
                            <TimesButton
                                callback={() => deleteConditional(qIndex, cIndex)}
                                className="danger"
                                size="lg"
                            />
                        </td>
                    </tr>
                ))}
                <tr onClick={() => addConditional(qIndex)}>
                    <td colSpan="2" />
                    <td className="action-button">
                        <PlusButton className="success" size="lg" />
                    </td>
                </tr>
                <tr>
                    <td className="conditional">
                        <input
                            name="condition"
                            type="text"
                            rows="1"
                            className="input"
                            value="DEFAULT"
                            disabled={true}
                        />
                    </td>
                    <td>
                        <select
                            className="dropdown"
                            name="defaultNext"
                            value={defaultNext}
                            onChange={e => handleDefaultNextChange(qIndex, e)}
                        >
                            {getQuestionsAfter(qIndex).map(qA => (
                                <option key={`default-${qIndex}-${qA.id}`} value={qA.id}>
                                    {qA.id}
                                </option>
                            ))}
                        </select>
                    </td>
                </tr>
            </tbody>
        </table>);