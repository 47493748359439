import React from "react";
import { SuccessCheck } from "../common/SuccessCheck/SuccessCheck";
import { LoadingSpinner } from "../LoadingSpinner";

export const OptoutAddNumberForm = (
  {updateState, errMsg, loading, success}
) => (
  <>
    <label className="label">Add phone number to list</label>
    <div className="control">
    <input
        className="input"
        name="phone"
        type="tel"
        onBlur={updateState}
        required
      />
      {/* {loading && } */}
      <LoadingSpinner loading={loading}/>
      {success && <SuccessCheck />}
      <p className="help is-info">Acceptable format: +x-xxx-xxx-xxxx, +x(xxx)xxxxxxx, +xxxxxxxxxxx. ( Dashes, parenth., and country code optional . )</p>
      {errMsg && <p className="help is-danger">{errMsg}</p>}
    </div>
    <br />
  </>
);
