import React from "react";

function SyntaxChecker(props) {

	const checkSyntax = () =>{
		const sample_string = props.sample;
		const marked_array = [];
		if(sample_string){
			[...sample_string].forEach((char)=>{
				if(char.includes("{") || char.includes("}")){
					marked_array.push(<mark>{char}</mark>)
				} else {
					marked_array.push(char)
				}
			})
		}

		return <div className="script-preview">{marked_array}</div>
	}

	return(
		<div>{checkSyntax()}</div>
	)
}

export default SyntaxChecker
