import React from "react";

import "./grids.css";

export const CheckboxGrid = ({ items, onChange, disabled=false }) => (
  <ul
    className="checkbox-grid"
    // style={{
    //   whiteSpace: "nowrap",
    //   overflow: "hidden",
    //   textOverflow: "ellipsis",
    // }}
  >
    {items.map((item, index) => (
      <li>
        <label className="checkbox" key={"check-" + index} title={item.name}>
        <input
          type="checkbox"
          onChange={(e) => onChange(e, index)}
          checked={item.value}
          disabled={disabled}
        />
          {/* <span 
          >
            </span> */}
          &nbsp;{item.name}
        </label>
      </li>
    ))}
  </ul>
);

// These are classes written by us since bulma CSS does not give access to the `flex-basis` property
const flexBasisClasses = { 2: 'flex-basis-50', 3: 'flex-basis-33', 4: 'flex-basis-25', 5: 'flex-basis-20' };

export const Flexgrid = ({ items, onChange, disabled = false, ulClassNames = '', ulId, numberOfColumns = 5, liClassNames = ''}) => {
  const listItemFlexBasisClass = flexBasisClasses[numberOfColumns];

  return (
    <ul className={`columns is-flex-wrap-wrap ${ulClassNames}`} id={ulId}>
      {items.map((item, index) => (
        <li className={`is-flex-grow-0 is-flex-shrink-0 p-2 ${listItemFlexBasisClass} ${liClassNames}`}>
          <label className="checkbox" key={`check-${index}`} title={item.name}>
            <input
              type="checkbox"
              className="mr-1"
              onChange={(e) => onChange(e, index)}
              checked={item.value}
              disabled={!!disabled}
            />
            {item.name}
          </label>
        </li>
      ))}
    </ul>
  );
};