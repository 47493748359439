/**
 * Creates a sort function for sorting on a field in an obj.
 * if you have a list of objects this will help sorting.
 * @param {string} field The name of the field to sort on
 * @param {bool} desc Sorts descending if true
 * @returns {function} The sort function for the given field
 */
export function sortByObjectField(field, desc = false) {
  return function(a, b) {
    if (desc) {
      return b[field] - a[field];
    }
    return a[field] - b[field];
  };
}
