import { fetchRequest } from "../../middleware/fetchMiddleware";
import { NAVIGATION_PREFIX } from "./types";

export function getNavigationElements() {
  return (dispatch) => {
    return dispatch(
      fetchRequest(NAVIGATION_PREFIX, "GET", "/navigation/displayOptions")
    );
  };
}
