import React from "react";
import { QuestionWrapper } from "./QuestionWrapper";
import {
  PlusButton,
  TimesButton,
} from "../generic/RoundButtons/RoundButtons";
import { ConditionalTable } from "./ConditionalTable";

export const Intro = (props) => {
  const { q, qIndex } = props;
  return (
    <QuestionWrapper 
      {...props}
    >
      <table className="closedq-answers">
        <thead>
          <td className="value">Value</td>
          <td>Text</td>
        </thead>
        {q.answers.map((ans, ansIndex) => (
          <tbody key={ansIndex}>
            <tr key={ansIndex}>
              <td className="value">
                <input
                  name="value"
                  type="number"
                  rows="1"
                  className="input"
                  value={ans.value}
                  onChange={(e) =>
                    props.handleAnswerChange(qIndex, ansIndex, e)
                  }
                />
              </td>
              <td className="answer">
                <input
                  name="text"
                  type="text"
                  rows="1"
                  className="input"
                  value={ans.text}
                  onChange={(e) =>
                    props.handleAnswerChange(qIndex, ansIndex, e)
                  }
                />
              </td>
              <td className="action-button">
                <TimesButton
                  callback={() => props.deleteAnswer(qIndex, ansIndex)}
                  className="danger"
                  size="lg"
                />
              </td>
            </tr>
          </tbody>
        ))}
        <tr onClick={() => props.addAnswer(qIndex)}>
          <td colSpan="2" />
          <td className="action-button">
            <PlusButton className="success" size="lg" />
          </td>
        </tr>
      </table>
      <ConditionalTable {...props} />
    </QuestionWrapper>
  );
};
