import React from "react";

export const Panel = ({ download, total=0, loading }) => (
  <>
    <h4 className="title has-text-centered">Opt In Panel Tools</h4>
    {/* <div className="field">
        <label className="label">Entries</label>
        {total}
    </div> */}
    <a className={`button is-info ${loading ? "is-loading" : ""}`} onClick={download} disabled={loading}>
      Download Panel Table
    </a>
  </>
);
