import React from "react";
import PropTypes from "prop-types";
import { LoadingSpinner } from "../LoadingSpinner";
import {
  UPLOAD_STATUS_MAP,
  UPLOAD_STATUS_VALUE_COLOR_MAP,
} from "../../util/constants";

export const UploadStatusProgressBar = ({
  status = UPLOAD_STATUS_MAP.NOTSTARTED,
}) => (
  <div className="">
    List upload process:&nbsp;
    <b>{status}</b>
    <LoadingSpinner
      loading={
        status === UPLOAD_STATUS_MAP.PROCESSING ||
        status === UPLOAD_STATUS_MAP.IMPORTING_PROCESSED
      }
    />
    <progress
      className={`progress is-${UPLOAD_STATUS_VALUE_COLOR_MAP[status].color}`}
      value={UPLOAD_STATUS_VALUE_COLOR_MAP[status].value}
      max={4}
    ></progress>
  </div>
);

UploadStatusProgressBar.propTypes = {
  status: PropTypes.string.isRequired,
};
