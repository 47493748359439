import React from "react";

import RenameModal from "./modals/RenameModal";
import CopyCampaignModal from "./modals/CopyCampaignModal";
import ConfirmActionModal from "./modals/ConfirmActionModal";
import PauseResumeCampaignModal from "./modals/PauseResumeCampaignModal";
import CampaignResultsModal from "./CampaignResults/CampaignResultsModal";

export const CampaignTools = ({
  name,
  campaignid,
  campaigns,
  active,
  history,
  jwt,
  vitals,
  exports: fileExports,

  requestCampaignLaunch,
  deleteCampaign,
  deactivateCampaign,
  enterSandboxMode,
  exitSandboxMode,

  startdate,
  enddate,
  subtwiliosid
}) => {
  return (
    <React.Fragment>
      <div className="tile is-child">
        <h1 className="title is-4 has-text-centered">TOOLS</h1>
      </div>

      <div className="tile is-parent">
        <div className="tile is-child">
          <div className="field">Campaign Name:
            <label className="label">{name}</label>
          </div>
          <div className="field">
            {active === "notyet" && (
              <div>Status:
                <label className="label"> Not Yet Launched</label>
              </div>
            )}
            {active === "sandbox" && (
              <div>Status:
                <label className="label"><span className="has-text-primary">Sandbox Mode</span></label>
              </div>
            )}
            {active === "prelaunch" && (
              <div>Status:
                <label className="label"><span className="has-text-success">Pre-Launch</span></label>
              </div>
            )}
            {(active === "active" || active == "deactivated") && (
              <div>
                Status:
                <label className="label">
                  <div><span className="has-text-success">Launched </span>on : {startdate}</div>
                </label>
              </div>
            )}
            {active === "deactivated" && (
              <label className="label">
                <div><span className="has-text-danger">Ended </span>on : {enddate}</div>
              </label>
            )}
          </div>
        </div>

        <div className="tile is-child">
          <div className="buttons is-right">
            {active === "notyet" && (
              <React.Fragment>
                <ConfirmActionModal
                  itemName={name}
                  actionName="launch campaign"
                  actionCallback={requestCampaignLaunch}
                  buttonClass="button is-success is-fullwidth"
                />
                <ConfirmActionModal
                  itemName={name}
                  actionName="Enter sandbox mode"
                  actionCallback={enterSandboxMode}
                  buttonClass="button is-info is-fullwidth"
                />
              </React.Fragment>
            )}
            {active === "sandbox" && (
              <ConfirmActionModal
                itemName={name}
                actionName="Exit sandbox mode"
                actionCallback={exitSandboxMode}
                buttonClass="button is-info is-fullwidth"
              />
            )}
            {active !== "deactivated" && active !== "notyet" && active !== "sandbox" && (
              <ConfirmActionModal
                itemName={name}
                actionName="end campaign"
                actionCallback={deactivateCampaign}
                buttonClass="button is-danger is-fullwidth"
              />
            )}
            {(active === "active" || active === "paused") && (
              <PauseResumeCampaignModal
                name={name}
                campaignid={campaignid}
                status={active}
                subtwiliosid={subtwiliosid}
            />) }

            {active === "notyet" && (
              <RenameModal
              buttonClass="button is-light is-fullwidth"
                campaigns={campaigns}
                campaignid={campaignid}
                campaignName={name}
              />
            )}

            <CopyCampaignModal
              campaignName={name}
              organizations={vitals.organizationarray}
              buttonClass="button is-light is-fullwidth"
            />
            {active !== "notyet" && (
              <CampaignResultsModal 
                selectedCampaignId={campaignid}
                useExisting={false}
                fileExports={fileExports}
                buttonClass="button is-warning is-fullwidth"
              />
            )}

            {active !== "active" && <ConfirmActionModal
              itemName={name}
              actionName="delete campaign"
              actionCallback={() => {
                deleteCampaign(history);
              }}
              buttonClass="button is-danger is-outlined is-fullwidth"
            />}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
