import { fetchRequest } from "../../middleware/fetchMiddleware";

export const GET_T2W_DATA = "GET_T2W_DATA";

export function getTextToWebData() {
  return dispatch => {
    return dispatch(
      fetchRequest(GET_T2W_DATA, "POST", "/getTextToWebData", {})
    );
  };
}
