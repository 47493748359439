import React from "react";
import { QuestionWrapper } from "./QuestionWrapper";

export const ClosePanelOptIn = props => {
  const { showPanelQuestions } = props;
  return (
    <QuestionWrapper
        {...props}
        lockQuestionText={!showPanelQuestions}
        lockId={true}
        lockType={true}
        disableUp={true}
        disableDown={true}
    />
  );
};
