import React, { Component } from "react";
import { connect } from "react-redux";
import { Notification } from "../Notification";
import { NavLink } from "react-router-dom";

class UploadEvent extends Component {
  render() {
    const { campaignid, campaigns, message, status } = this.props;
    const campaign = campaigns.find((c) => c.campaignid === campaignid);

    return (
      <Notification {...this.props} className={status === "failed" ? "is-danger" : "is-info"}>
        {status === "processed" && (
          <>Action Required. Recipient list upload complete for campaign <b>{campaign.name}</b>
          is done.</>
        )}

        {status === "complete" && (
          <>
            Recipient list upload complete for campaign <b>{campaign.name}</b>
            is done.
          </>
        )}

        {status === "failed" && (
          <>
            There was a problem uploading list for campaign <b>{campaign.name}</b>. 
          </>
        )}

        <NavLink to={`/dashboard/campaigns/${campaignid}/recipients`}>
          Go to recipients
        </NavLink>
        <br />
        {message}
      </Notification>
    );
  }
}

const mapStateToProps = (state) => ({
  campaigns: state.NAID.campaigns,
});

export default connect(mapStateToProps)(UploadEvent);
