import { createJSONFetchRequest, downloadFile } from "../../util/helper";

export function downloadPanelTable() {
  return (dispatch, getState) => {
    dispatch({type:"DOWNLOAD_PANEL_IS_LOADING"});
    createJSONFetchRequest("/downloadPanelTable", { jwt: getState().auth.jwt })
      .then(response => response.text())
      .then(data => {
        dispatch({type:"DOWNLOAD_PANEL_POST_SUCCESS", data: data });
        downloadFile("panel_table.csv", data);
      })
      .catch(error => {
        dispatch({type: "DOWNLOAD_PANEL_HAS_ERROR"});
      });
  };
}

