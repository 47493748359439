import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ConfirmActionModal from "../modals/ConfirmActionModal";
import { logoutAllAgents } from "../../redux/Agents/actions";
import PropTypes from "prop-types";

const LogoutAllModal = ({ logout, campaignid, refreshCallback }) => (
  <ConfirmActionModal
    itemName={`campaign ${campaignid}`}
    actionName="Log out all agents"
    actionCallback={() =>
      logout(campaignid).then((item) => refreshCallback([campaignid]))
    }
    buttonClass="button is-light is-fullwidth"
  />
);

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logout: (campaignid) => logoutAllAgents(campaignid),
    },
    dispatch
  );

LogoutAllModal.propTypes = {
  campaignid: PropTypes.number.isRequired,
  logoutAllAgents: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LogoutAllModal);
