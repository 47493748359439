import React, {Component} from "react";
import { Checklist } from "./generic/Checklist/Checklist";

class PrelaunchChecklist extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    static getDerivedStateFromProps(props, state) {
        const {
            list,
            prompt,
            script,
            login,
            listlength
        } = props.selectedCampaignData;
        return {
            list: [
                {description: "Prompt Created", checked: prompt && prompt.length},
                {description: "Script Created", checked: script && script.length},
                {description: "Agent Logins Created", checked: login && login.length},
                {description: "Uploaded recipient list", checked: listlength},
                {description: "Recipient list has more than 5 entries.", checked: listlength && listlength > 5}
            ]
        };
    }
    render() {
        return <Checklist
            list={this.state.list}
            title={"Prelaunch Checklist"}
        />
    }

}

export default PrelaunchChecklist;