import { USER_ROLES_LOOKUP_TABLE } from "../../util/constants";
import { fetchRequest } from "../../middleware/fetchMiddleware";
export const EDIT_USER = "EDIT_USER";
export const USERS_IS_LOADING = "USERS_IS_LOADING";
export const USERS_HAS_ERROR = "USERS_HAS_ERROR";
export const USERS_POST_SUCCESS = "USERS_POST_SUCCESS";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";

/**
 * Pass in the new user info and the index at which to
 * replace it with.
 */
export const editUser = (newUserInfo, index) => {
  return dispatch => {
    dispatch({
      type: EDIT_USER,
      newUserInfo: newUserInfo,
      index: index
    });
  };
};

export function getUsers(orgid, campaignid) {
  return (dispatch, getState) => {
    let params = {
      jwt: getState().auth.jwt,
      orgid,
      campaignid
    };

    return dispatch(fetchRequest("USERS", "POST", "/retrieveUserData", params));

  };
}

export function editUserSuccess() {
  return dispatch => {
    dispatch({
      type: EDIT_USER_SUCCESS
    });
  };
}

export function editUserPost(params, name) {
  return dispatch => {
    return dispatch(fetchRequest(name, "POST", "/alteruserpermissions", params));
  }
}

/** Wrapper functions to edit User. Set up params for POST */
export function addUserToCampaign(userid, campaignid) {
  return (dispatch) => {
    const params = {
      action: "addtocampaign",
      userid: userid,
      campaignid: campaignid
    };

    return dispatch(editUserPost(params, "ADD_USER_TO_CAMPAIGN"));
  };
}
/**
 * Add a user to a list of campaigns at the same time.
 * @param {string} userid The userid string 
 * @param {Array<number>} campaignList The list of campaigns to add to
 * @returns 
 */
export function addUserToCampaignList(userid, campaignList) {
  return (dispatch) => {
    const params = {
      action: "addtocampaign",
      userid: userid,
      campaignList: campaignList
    };

    return dispatch(editUserPost(params, "ADD_USER_TO_CAMPAIGN"));
  };
}

export function removeUserFromCampaign(userid, campaignid) {
  return (dispatch, getState) => {
    const params = {
      action: "subtractfromcampaign",
      userid: userid,
      campaignid: campaignid,
      jwt: getState().auth.jwt
    };
    return dispatch(editUserPost(params, "REMOVE_USER_FROM_CAMPAIGNS"));
  };
}

export function updateUserPermissions(userid, permissionlevel) {
  return (dispatch, getState) => {
    const state = getState();
    const params = {
      action: "alterpermissionlevel",
      userid: userid,
      permissionlevel: USER_ROLES_LOOKUP_TABLE.indexOf(permissionlevel),
      jwt: state.auth.jwt
    };
    return dispatch(editUserPost(params, "UPDATE_USER_PERMISSIONS"));
  };
}

export function updateUserAnalyticsCheckboxConfig(analyticscheckboxconfig) {
  return (dispatch,getState) => {

    const state = getState();
    const params = {
      action: "updateanalyticscheckboxconfig",
      userid: state.auth.userid,
      analyticscheckboxconfig: analyticscheckboxconfig,
      jwt: state.auth.jwt
    };
    return dispatch(editUserPost(params, "UPDATE_USER_ANALYTICSCHECKBOXCONFIG"));
  };
}

export function deleteUser(userid) {
  return (dispatch, getState) => {
    const params = {
      action: "deleteuser",
      userid: userid,
      jwt: getState().auth.jwt
    };

    dispatch(editUserPost(params, "DELETE_USER"));
  };
}

export const ADD_USER_AUTHY_PREFIX = "ADD_USER_AUTHY";
export function addUserAuthy(email, phone, password, permissionlevel, organizationid, userid) {
  return (dispatch, getState) => {

    const params = {email, phone, permissionlevel, organizationid, password, newUserid: userid};

    return dispatch(fetchRequest(ADD_USER_AUTHY_PREFIX, "POST", "/createUserAuthy", params));
  }
}

// There's a new restpoint alteruserpermssions which can change permissions,
// add or subtract a user from a campaign, or delete a user.
// Other than the jwt it requires a userid, an action, if the action
// is addtocampaign or subtractfromcampaign it requires a campaignid,
// if the action is alterpermissionlevel it requires a permissionlevel,
// if the action is deleteuser it requires nothing else.