import React, { Component } from "react";

class SidebarContainer extends Component {
  state = {
    data: [],
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      data: nextProps.organizations.map((org) => ({
        ...org,
        campaigns: nextProps.campaigns
          .filter((campaign) => campaign.organizationid === org.organizationid)
          .sort((a, b) => a.campaignid - b.campaignid),
      })),
    };
  }

  render = () => {
    return (
      <div className="column is-2">
        <div className="has-background-grey-lighter">
          <aside className="menu">
            {this.props.render({ campaignsByOrganization: this.state.data })}
          </aside>
        </div>
      </div>
    );
  };
}

export default SidebarContainer;
