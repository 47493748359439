import React, { Component } from "react";

import UpdateCampaignCostsModal from "./modals/UpdateCampaignCostsModal";
import List from "./List";

import CurrentSpendField from "./CurrentSpendField";
import { centsToDollars } from "../util/helper";

const headers = [
  {
    header: "Description",
    title: "Brief Charge Description",
    accessor: "description"
  },
  {
    header: "Charge",
    title: "Charge Amount",
    accessor: "charge"
  },
  {
    header: "Repeat",
    title: "Charge Recurrance Interval",
    accessor: "interval"
  }
];

class CostTools extends Component {
  render() {
    const {
      campaignid,
      smssurcharge,
      campaigncosts,
      costperagenthour,
      totalagenthours,
      spendlimit,
      prefix,
      edit,
    } = this.props;

    return (
      <div className="tile is-parent is-vertical">
        <h4 className="title is-4  has-text-centered">{prefix} COSTS</h4>
        <div className="tile is-parent">
          <div className="tile is-child">
            <div className="field">
              <div className="field">
                Total Recorded Agent Hours
                <label className="label">
                  --
                </label>
              </div>
              <div className="field">
                Cost per Agent Hour
                <label className="label">
                  $ {costperagenthour}
                </label>
              </div>

              {!!smssurcharge && <div className="field">
                Cost Per SMS
                <label className="label">
                  $ {smssurcharge}
                </label>
              </div>}

              <div className="field">
                Spend Limit
                <label className="label">
                  $ {centsToDollars(spendlimit)}
                </label>
              </div>

              <div className="field">
                Currently Spent
                <label className="label">
                  $ <CurrentSpendField initParams={[undefined, campaignid]} />
                </label>
              </div>
            </div>

            {campaigncosts.length > 0 && (
              <div className="field">
                Additional Costs
                <List list={campaigncosts} headers={headers} />
              </div>
            )}
          </div>
          {edit && <div className="tile is-child">
            <div className="buttons is-right">
              <UpdateCampaignCostsModal {...this.props} />
            </div>
          </div>}
        </div>
      </div>
    );
  }
}

export default CostTools;
