import React from "react";
import { BulmaButtonProper } from "../generic/Buttons/BulmaButtonProper";

export const ResetProportionsButton = ({ setFieldAll }) => (
  <BulmaButtonProper
    title={"Set all strata trgt. proportions to 0"}
    onClick={() => setFieldAll("assignment_weight", 0)}
  >
    Proportions to 0
  </BulmaButtonProper>
);
