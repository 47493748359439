// Authorization is managed here.
// This does a redirect check and if we're valid then we go to the dashboard
// whereas if we're not valid we're sent to the login.

import React, { Component } from "react";
import { connect } from "react-redux";
import {  Route, Redirect, Switch } from "react-router-dom";

import DCMContainer from "./DCMContainer";
import LoginModal from "../components/modals/LoginModal";
import AuthyLoginModal from "../components/Authy/AuthyLoginModal";

class AuthContainer extends Component {
  render() {
    const { auth } = this.props;
    return (
      <Switch>
        <DCMRedirectCheck exact path="/" auth={auth} />
        <Route
          path={"/login"}
          render={props => <LoginModal auth={auth} {...props} visible={true} />}
        />
        <Route
          path={"/dashboard"}
          render={props => <DCMContainer auth={auth} {...props} />}
        />
        <Route
          path="/authy-login"
          render={props => <AuthyLoginModal auth={auth} {...props} visible={true} />}
        />
      </Switch>
    );
  }
}

// If we are not logged in. Redirect to login page
const DCMRedirectCheck = ({ auth, ...props }) => {
  return <Route
    {...props}
    render={props => {
      // NOTE: This should still work, because JWT wont be set until the end
      return auth.userid !== "" && auth.jwt !== "" ? (

        <Redirect
          to={{
            pathname: "/dashboard",
            state: { from: props.location }
          }}
        />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location }
          }}
        />
      );
    }}
  />;
};

const mapStateToProps = state => {
  return { auth: state.auth, reduxHistory: state.router };
};

export default connect(mapStateToProps)(AuthContainer);
