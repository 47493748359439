import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { generateStrataTableFromFile } from "../../redux/Quotas/actions";

import ConfirmActionModal from "../modals/ConfirmActionModal";
import { BulmaButtonProper } from "../generic/Buttons/BulmaButtonProper";
import { ErrorNotification } from "../generic/Notifications/ErrorNotification";

class QuotasFileUpload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filename: null,
    };
  }

  submit = () => {
    console.log("I ran!", this.props.filename, this.props.loading);
    if (this.state.filename === null || this.props.loading) {
      return;
    }

    this.props.generateStrataTableFromFile(this.file.files[0]);
  };

  fileChanged = (e) => {
    if (!e.target.files.length) {
      return;
    }

    this.setState({
      filename: e.target.files[0].name,
    });
  };

  clearFile = () => {
    this.setState({ filename: null });
  };

  render = () => {
    // If quotas exist display a message
    const { updatingExisting, loading, error } = this.props;
    console.log(loading, error);
    const message = updatingExisting
      ? "Before uploading, make sure the existing file matches the existing strata."
      : "Upload a file that represents the desired strata: 1 column for each variable plus a column 'target_complete' with the target for that strata., 1 row for each combination.";
    const buttonActionName = updatingExisting ? "Update" : "Create";

    return (
      <div className="control">
        <label className="label">{message}</label>
        <div className={`file ${this.state.filename ? "has-name" : ""}`}>
          <label className="file-label">
            <input
              className="file-input"
              id="myFileNew"
              name="files[]"
              multiple=""
              type="file"
              accept=".csv"
              ref={(f) => (this.file = f)}
              onChange={this.fileChanged}
            />
            <span class="file-cta">
              <span class="file-icon">
                <i class="fas fa-upload"></i>
              </span>
              <span class="file-label">Choose a file…</span>
            </span>
            {this.state.filename && (
              <span class="file-name">{this.state.filename}</span>
            )}
          </label>
          {this.state.filename && (
            <>
              <QuotasFileUploadConfirm
                onClick={this.submit}
                currentAction={buttonActionName}
              />
              <BulmaButtonProper
                customClass={"is-danger"}
                onClick={this.clearFile}
              >
                Cancel
              </BulmaButtonProper>
            </>
          )}
          <br />
        </div>
        <div>
          <br />
          <ErrorNotification condition={error}>
            File Parser Error: {this.props.errorCode} - {this.props.errorMessage}
          </ErrorNotification>
        </div>
      </div>
    );
  };
}

const QuotasFileUploadConfirm = ({
  loading,
  onClick,
  currentAction = "Create",
}) => (
  <ConfirmActionModal
    actionName={`${currentAction} strata from file`}
    actionCallback={onClick}
    buttonClass="button is-success ml-3"
    message="Creates / updates strata based on contents of the uploaded file"
    loading={loading}
  />
);

const mapStateToProps = (state) => ({ ...state.quotas.quotasFileParse });

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      generateStrataTableFromFile,
    },
    dispatch
  );

const QuotasFileUploadConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(QuotasFileUpload);

export default QuotasFileUploadConnected;
