import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./styles/index.css";
import App from "./components/App";
// import registerServiceWorker from "./registerServiceWorker";
import store, {history} from "./store";
import { AppContainer } from "react-hot-loader";


import { Route } from "react-router-dom";

import { unregister as unregisterServiceWorker } from './registerServiceWorker'

// BULMA and FONTAWESOME-ICONS
// import "bulma/css/bulma.css";
import "./styles/bulma_import.css";
import { ConnectedRouter } from "connected-react-router";

const target = document.getElementById("root");

// Modified to include hot-reloading based on this article
// https://daveceddia.com/hot-reloading-create-react-app/

const render = Component =>
  ReactDOM.render(
    <Provider store={store}>
      <AppContainer>
        <ConnectedRouter history={history}>
        {/* <BrowserRouter basename="/"> */}
          <Route path="/" component={App} />
        {/* </BrowserRouter> */}
        </ConnectedRouter>
      </AppContainer>
    </Provider>,
    target
  );

unregisterServiceWorker();

render(App);

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept("./components/App", () => {
    render(App);
  });
}
