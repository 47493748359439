import React, { Component } from "react";
import Modal from "react-modal";

class ModalContainerFree extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
    this.toggleOpen = this.toggleOpen.bind(this);
  }

  componentDidMount() {
    if (this.props.open) {
      this.toggleOpen();
    }
  }

  toggleOpen() {
    this.props.toggleModal();
  }
  
  componentWillReceiveProps(nextProps) {
    if (nextProps.open) {
      this.setState({open: nextProps.open});
    }
  }
  
  render() {
    const { customButtonClass, modalCardClasses, open } = this.props;
    const buttonClass = customButtonClass ? customButtonClass : "button is-info";
    return (
        <div className="modal-container">
        <Modal isOpen={open} className={"modal" + (this.state.open ? " is-active" : "")}>
        <div className="modal-background" onClick={this.toggleOpen} />
        <div className={`modal-card ${modalCardClasses}`}>
        {this.props.children({toggleOpen: this.toggleOpen})}
        </div>
        </Modal>
        </div>
    );
  }
}

export const ModalHeader = ({ children, ...props }) => {
  return <header className="modal-card-head" {...props}>{children}</header>;
};

export const ModalBody = ({ children, ...props }) => {
  return <section className="modal-card-body" {...props}>{children}</section>;
};

export const ModalFooter = ({ children, toggleOpen, hideCancel }) => {
  return (
    <footer className="modal-card-foot">
      {children}
      {!hideCancel && (
        <button className="button is-danger" onClick={toggleOpen}>
          Cancel
        </button>
      )}
    </footer>
  );
};

export default ModalContainerFree;
