import React from "react";

export const SnapshotControls = ({
  data = [],
  applyChange,
  onChange,
  convertedSnapshotDates,
  range
}) => (
  <div className="field is-grouped">
    {range && (
      <div className="control field is-horizontal">
        <label className="label">From: &nbsp;</label>

        <div className="select">
          <select
            className="dropdown"
            name="start"
            onChange={onChange}
            onBlur={onChange}
            defaultValue="start"
          >
            <option value={"start"}>Campaign Launch</option>

            {data.map((item, i) => {
              return (
                <option value={item.timestamp} key={`start-${i}`}>
                  {convertedSnapshotDates[i]}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    )}
    <div className="control field is-horizontal">
      <label className="label">{range ? "To: " : "Date: "}&nbsp;</label>
      <div className="select">
        <select
          className="dropdown"
          name="end"
          onChange={onChange}
          onBlur={onChange}
          defaultValue="current"
        >
          {data.map((item, i) => {
            return (
              <option value={item.timestamp} key={`end-${i}`}>
                {convertedSnapshotDates[i]}
              </option>
            );
          })}
          <option value={"current"}>Now</option>
        </select>
      </div>
    </div>
    <p className="control">
      <a className="button" onClick={applyChange}>
        Apply
      </a>
    </p>
    <p className="control">
      <label className="label">
        <input
          className="checkbox"
          type="checkbox"
          name="range"
          onChange={onChange}
          defaultChecked={true}
        />
        &nbsp;Select Date Range
      </label>
    </p>
  </div>
);
