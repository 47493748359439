import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Panel } from "./Panel";
import { createJSONFetchRequest, downloadFile } from "../../util/helper";
import { downloadPanelTable } from "../../redux/Panel/actions";

class PanelContainer extends Component {
  constructor(props) {
    super(props);

    this.executeDownloadPanelTable = this.executeDownloadPanelTable.bind(this);
  }

  executeDownloadPanelTable() {
    const { downloadPanel } = this.props.panel;
    if (!downloadPanel.loading) this.props.downloadPanelTable();
  }

  render() {
    return <Panel download={this.executeDownloadPanelTable} loading={this.props.panel.downloadPanel.loading} />;
  }
}

function mapStateToProps(state) {
  return {
    panel: state.panel
  };
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      downloadPanelTable: () => downloadPanelTable()
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PanelContainer);
