import React from "react";
import { Notification } from "../Notification";

const UpdateCampaignDataError = ({campaignid, ...props}) => {

  return (
    <Notification {...props} className="is-danger">
      Failed to update campaign data for campaign <b>({campaignid})</b>. Refresh and try again.
    </Notification>
  );
};

export default UpdateCampaignDataError;
