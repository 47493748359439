import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";

import { bindActionCreators } from "redux";
import { changePasswordPost } from "../../redux/Auth/actions";

class ChangePasswordModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      valid: false,
      message: ""
    };
    this.attemptChangePassword = this.attemptChangePassword.bind(this);
    this.toggleOpen = this.toggleOpen.bind(this);
    this.checkValid = this.checkValid.bind(this);
  }

  toggleOpen() {
    this.setState({ open: !this.state.open });
  }

  checkValid() {
    let result = true;

    if (this.claimedpassword1.value != this.claimedpassword2.value) {
      this.setState({ message: "Passwords Don't Match", valid: false });
      return 0;
    }
    if (this.claimedpassword1.value.length < 5) {
      this.setState({ message: "Passwords Too Short", valid: false });
      return 0;
    }
    this.setState({
      valid: true,
      message: ""
    });
  }

  attemptChangePassword() {
    this.props.changePassword(
      this.claimedpasswordold.value,
      this.claimedpassword1.value
    );

    this.toggleOpen();
  }
  render() {
    return (
      <div>
        <a className="navbar-item" onClick={this.toggleOpen} href="#">
          Change Password
        </a>
        <Modal
          isOpen={this.state.open}
          className={"modal" + (this.state.open ? " is-active" : "")}
        >
          <div className="modal-background" onClick={this.toggleOpen} />
          <div className="modal-card">
            <header className="modal-card-head">Change Password</header>
            <section className="modal-card-body">
              <label className="label">
                Old Password
                <input
                  className="input"
                  type="password"
                  autoComplete="on"
                  name="claimedpasswordold"
                  placeholder="old password"
                  ref={claimedpasswordold => {
                    this.claimedpasswordold = claimedpasswordold;
                  }}
                  onChange={this.checkValid}
                />
              </label>
              <label className="label">
                New Password
                <input
                  className="input"
                  type="password"
                  autoComplete="on"
                  name="claimedpassword1"
                  placeholder="new password"
                  ref={claimedpassword1 => {
                    this.claimedpassword1 = claimedpassword1;
                  }}
                  onChange={this.checkValid}
                />
              </label>
              <label className="label">
                Reinsert New Password
                <input
                  className="input"
                  type="password"
                  autoComplete="on"
                  name="claimedpassword2"
                  placeholder="new password"
                  ref={claimedpassword2 => {
                    this.claimedpassword2 = claimedpassword2;
                  }}
                  onChange={this.checkValid}
                />
              </label>
              <p className="help is-danger">{this.state.message}</p>
            </section>
            <footer className="modal-card-foot">
              <button
                className="button is-info"
                disabled={!this.state.valid}
                onClick={() => {
                  this.attemptChangePassword();
                }}
              >
                Change Password
              </button>
              <button className="button" onClick={() => this.toggleOpen()}>
                Cancel
              </button>
            </footer>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePassword: (oldPassword, newPassword) =>
        changePasswordPost(oldPassword, newPassword)
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(ChangePasswordModal);
