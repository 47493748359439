import { combineReducers } from "redux";
import { loadStateReducer } from "../../util/reusableReducers";

const getTextToWebData = loadStateReducer({
  GET_T2W_DATA_IS_LOADING: "loading",
  GET_T2W_DATA_HAS_ERROR: "error",
  GET_T2W_DATA_POST_SUCCESS: "success"
});

const getTextToWebExport = loadStateReducer({
  T2W_EXPORT_IS_LOADING: "loading",
  T2W_EXPORT_HAS_ERROR: "error",
  T2W_EXPORT_GET_SUCCESS: "success"
});

export default combineReducers({ getTextToWebData, getTextToWebExport });

// Yer gonna love this. The reason the action worked but the reducer didn't? I'd named the success case "GET_T2W_DATA_GET_SUCCESS". But it's a POST, so another typo.
