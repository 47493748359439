// Shows and manages users via UserFilters and via ListWithLoading.
// This also contains the ConfirmDeleteUserModal which is activated and filled for a particular user
// when the user's corresponding delete is pressed.

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import {
    assignBandwidthRegistrationToCampaign,
    createNewBandwidthRegistration,
    deleteBandwidthRegistration,
    getAllBandwidthRegistrations,
    purchaseBandwidthLines,
    releaseBandwidthLinesFromSettings,
    unassignBandwidthRegistration,
    updateBandwidthRegistration,
    linkExistingRegistration,
    getBandwidthRegistrationById,
} from "../../redux/Bandwidth/actions";
import { addNotification } from "../../redux/GlobalNotifications/actions";

// import { BANDWIDTH_SETTINGS_STATES } from "../util/constants";
import AggregatorSettingsPage from "./AggregatorSettingsPage";
import { clearErrorMsg } from "../../redux/FetchResponseManager/actions";

function ConnectedAggregatorSettingsPage(props) {
  return <AggregatorSettingsPage {...props}/>
}

const mapStateToProps = (state) => {
    // Think of mapping the campaigns here, or in the API prefferably
    const bwSettings = new Map();
    const { maxCampaignsPerRegistration, settings } = state.bandwidth.getAllBandwidthRegistrations.data || {};

    if (Array.isArray(settings)) {
        // Maps iterate by the order of insertion, and settings are already sorted by status, number of assigned campaigns, and IDs.
        for(const setting of settings) {
            bwSettings.set(setting.settings_id, setting);
        }
    }
    
    const registrationData = state.bandwidth.getBandwidthRegistrationById.data ? state.bandwidth.getBandwidthRegistrationById.data.campaign : null;
    return {
        campaigns: state.NAID.campaigns,
        error: state.bandwidth.error,
        bwSettings,
        registrationData,
        maxCampaignsPerRegistration,
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getAllBandwidthRegistrations,
            updateBandwidthRegistration,
            assignBandwidthRegistrationToCampaign,
            unassignBandwidthRegistration,
            createNewBandwidthRegistration,
            deleteBandwidthRegistration,
            releaseBandwidthLinesFromSettings,
            purchaseBandwidthLines,
            addNotification,
            clearErrorMsg,
            linkExistingRegistration,
            getBandwidthRegistrationById,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedAggregatorSettingsPage);
