import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getUploadURL } from "../../redux/Recipients/actions";
import { BulmaButton } from "../generic/Buttons/BulmaButton";

class RecipientUploadOptions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      opt_out: true,
      merge: true,
      misc_update: false
    };
  }

  submit = (e) => {
    const {
      loading,
      directUploadingInProgress,
      selectedCampaignId,
      getUploadURL,
    } = this.props;
    if (
      this.state.filename === undefined ||
      loading ||
      directUploadingInProgress
    ) {
      return;
    }

    getUploadURL(
      selectedCampaignId,
      this.file.files[0],
      this.state.merge,
      this.state.opt_out,
      this.state.misc_update,
      this.state.filename.includes(".gz")
    );
  };

  /**
   * Don't use with non checkbox values
   * @param {*} e
   */
  optionsChanged = (e) => {
    this.setState({
      [e.target.name]: e.target.checked,
    });
  };

  fileChanged = (e) => {
    if (!e.target.files.length) {
      return;
    }

    this.setState({
      filename: e.target.files[0].name,
    });
  };

  render() {
    const { loading, error, cancel, directUploadingInProgress } = this.props;
    return (
      <>
        <h4 className="title is-5">Upload Options</h4>
        <div class="control">
          <input
            type="checkbox"
            name="opt_out"
            checked={this.state.opt_out}
            onChange={this.optionsChanged}
          />
          &nbsp; Filter out recipients from opt-out list <br />
          <input
            type="checkbox"
            name="merge"
            checked={this.state.merge}
            onChange={this.optionsChanged}
          />
          &nbsp; Merge with current Recipient List <br />

          <input 
            type="checkbox"
            name="misc_update"
            checked={this.state.misc_update}
            onChange={this.optionsChanged}
          />
          &nbsp; Replace existing miscellaneous variables <br />
          <div className={`file ${this.state.filename ? "has-name" : ""}`}>
            <label className="file-label">
              <input
                className="file-input"
                id="myFileNew"
                name="files[]"
                multiple=""
                type="file"
                accept=".csv,.gz"
                ref={(f) => (this.file = f)}
                onChange={this.fileChanged}
              />
              <span class="file-cta">
                <span class="file-icon">
                  <i class="fas fa-upload"></i>
                </span>
                <span class="file-label">Choose a file…</span>
              </span>
              {this.state.filename && (
                <span class="file-name">{this.state.filename}</span>
              )}
            </label>
          </div>
        </div>
        {/* <hr /> */}
        <br />
        {/* TODO: make this disabled before we select a file */}
        <BulmaButton
          customClass="is-success"
          onClick={this.submit}
          loading={loading || directUploadingInProgress}
          error={error}
          disabled={this.state.filename === undefined || loading || directUploadingInProgress}
        >
          Process list for upload
        </BulmaButton>

        <BulmaButton customClass="is-light" onClick={cancel}>
          Cancel
        </BulmaButton>

        <br />
      </>
    );
  }
}

RecipientUploadOptions.propTypes = {
  change: PropTypes.func,
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUploadURL: (campaignid, filename, merge, opt_out, misc_update) =>
        getUploadURL(campaignid, filename, merge, opt_out, misc_update),
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(RecipientUploadOptions);
