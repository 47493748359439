import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SelectSearch from "react-select-search";

import ModalContainer, {
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "./ModalContainer";

import {
  addUserToCampaign,
  addUserToCampaignList,
} from "../../redux/Users/actions";
import { USER_ROLES_LOOKUP_TABLE, USER_ROLES } from "../../util/constants";

/**
 * @typedef {Object} CampaignData
 * @property {number} campaignid The id for the campaign
 * @property {number} organizationid The org the campaign is under
 * @property {string} name The campaign name
 */

/**
 * @typedef {Array<CampaignData>} CampaignList
 */

/**
 * @typedef {Array<{campaignid: number, value: number, name: string}>} CampaignOptionsList
 */

/**
 * @typedef {Object} Props
 * @property {CampaignList} campaigns All the campaigns in the system
 * @property {CampaignList} userCampaigns The campaigns assigned to the selected user
 * @property {string} userid - The userid of the selected user ( not the logged in user )
 * @property {number} userorganizationid - Selected user organizationid
 * @property {boolean} loading - True if a request if loading
 * @property {number} userPermissionLevel - The permissionlevel of the selected user
 * @property {function} addUserToCampaign - Assignes a single campaign to a user to the DB
 * @property {function} userDataUpdated - Forces a reload of the user data
 * @property {function} addUserToCampaignList - Assignes a list of campaigns to a user to the DB
 */

/**
 * Modal for adding Users to campaigns
 * @param {Props} props - AddUserToCampaignModal props
 * @returns {React.JSX.Element} The component jsx
 */
function AddUserToCampaignModal({
  addUserToCampaign,
  userid,
  userorganizationid,
  campaigns,
  userCampaigns,
  loading,
  userDataUpdated,
  userPermissionLevel,
  addUserToCampaignList,
}) {
  /**
   * @type {CampaignOptionsList}
   */
  const initArray = [];
  const [selectedCampaigns, setSelectedCampaign] = useState(initArray);

  function selectCampaigns(_, campaignIdsWithMoreData) {
    try {
      campaignIdsWithMoreData.forEach((campaignObject) => {
        const campaignid = parseInt(campaignObject.value);

        if (isNaN(campaignid)) {
          throw new Error("Invalid campaign id");
        }

        if (!campaigns.find((c) => c.campaignid === campaignid)) {
          throw new Error("Campaign not found");
        }

        if (selectedCampaigns.find((c) => c.campaignid === campaignid)) {
          throw new Error("Campaign already selected");
        }
      });
      setSelectedCampaign(campaignIdsWithMoreData);
    } catch (error) {
      console.log("Error", error);
      return;
    }
  }

  function resetSelected() {
    setSelectedCampaign([]);
  }

  function updateList(selCampaigns) {
    // Remove already assigned campaigns
    let addOptions = campaigns.filter(
      (item) =>
        !userCampaigns.find((c) => c.campaignid === item.campaignid) &&
        !selCampaigns.find((c) => c.campaignid === item.campaignid)
    );

    // Only keep those for which the user (in that line) has the same org as the campaign.
    if (
      USER_ROLES_LOOKUP_TABLE[userPermissionLevel] == USER_ROLES.campaignAdmin
    ) {
      addOptions = addOptions.filter(
        (item) => userorganizationid == item.organizationid
      );
    }

    // Finally, sort the campaigns by campaign id and render
    addOptions = addOptions.sort((a, b) => b.campaignid - a.campaignid);

    return addOptions.map((item) => ({
      name: `${item.campaignid} - ${item.name}`,
      value: item.campaignid,
    }));
  }

  const addOptions = updateList(selectedCampaigns);

  const openCampaignsExist =
    addOptions.length !== 0 ||
    (addOptions.length === 0 && selectedCampaigns.length !== 0);

  return (
    <ModalContainer name="+" customButtonClass="button is-rounded">
      {(modalState) => (
        <React.Fragment>
          <ModalHeader>Add User to Campaign</ModalHeader>
          <ModalBody>
            <div className="tile is-ancestor">
              <div className="tile is-parent">
                {openCampaignsExist ? (
                  <>
                    <div className="tile is-child is-6">
                      <div className="field">
                        <label className="label">
                          Add <b>{userid}</b> to campaigns:{" "}
                        </label>
                        <SelectSearch
                          options={addOptions}
                          placeholder="Select Campaign(s)"
                          search={true}
                          onChange={selectCampaigns}
                          multiple={true}
                          value={selectedCampaigns.map((c) => c.value)}
                          menuShouldScrollIntoView={false}
                        />
                      </div>
                    </div>
                    <div className="tile is-child is-right pl-3">
                      {selectedCampaigns.length !== 0 && (
                        <div className="container">
                          <label className="label">
                            Assign user to campaigns:
                          </label>
                          <ul className="">
                            {selectedCampaigns.map((item) => (
                              <li>
                                ({item.value}) - {item.name}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  "User already assigned to all availeable campaigns."
                )}
              </div>
            </div>
          </ModalBody>
          <ModalFooter {...modalState} hideCancel={true}>
            <div>
              {openCampaignsExist && (
                <a
                  className={"button is-info" + (loading ? " is-loading" : "")}
                  onClick={(e) => {
                    userDataUpdated();
                    modalState.toggleOpen();
                    const results = addUserToCampaignList(
                      userid,
                      selectedCampaigns.map((c) => c.value)
                    );
                    resetSelected();
                    return results;
                  }}
                >
                  Confirm
                </a>
              )}
              <button
                className="button is-danger"
                onClick={() => {
                  resetSelected();
                  modalState.toggleOpen();
                }}
              >
                Cancel
              </button>
            </div>
          </ModalFooter>
        </React.Fragment>
      )}
    </ModalContainer>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addUserToCampaign: (userid, campaignid) =>
        addUserToCampaign(userid, campaignid),
      addUserToCampaignList,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(AddUserToCampaignModal);
