import React from "react";
import { NavLinkListItemWrapper } from "./NavLinkListItemWrapper";

export const SidebarElementHeader = ({ name, link, disableLink, uniqueId, children }) => (
  <React.Fragment key={"o-fr-" + uniqueId}>
    <p className="menu-label">
      {!disableLink ? (
        <NavLinkListItemWrapper
          to={link}
          className="has-text-weight-bold"
        >
          {name}
        </NavLinkListItemWrapper>
      ) : (
        <li className={"has-text-weight-bold"}>{name}</li>
      )}
    </p>
    {children}
    
  </React.Fragment>
);
