import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Notification } from '../Notification';

import { BANDWIDTH_SETTINGS_STATES } from '../../../util/constants';

class RegistrationUpdateNotification extends Component {
    render() {
        const { bwSettingsItemId, updateType, bwSettingsItems } = this.props;
        const bwSettings = bwSettingsItems.find(item => item.id === bwSettingsItemId);
        const {
            BLANK,
            REGISTRATION_PENDING,
            REGISTRATION_READY,
            ACCOUNTS_CREATED,
            NUMBER_PURCHASE_PENDING,
            NUMBER_PURCHASE_COMPLETE,
            NUMBER_REG_PENDING,
            NUMBER_REG_COMPLETE,
            REGISTRATION_AVAILABLE,
            REGISTRATION_AVAILABLE_PARTIAL,
            REGISTRATION_CAMPAIGN_UNLINKED,
            REGISTRATION_CAMPAIGN_ACTIVE,
            NUMBER_UNREG_PENDING,
            NUMBER_UNREG_COMPLETE,
            NUMBER_RELEASE_PENDING,
            NUMBER_RELEASE_COMPLETE,
            REG_REMOVE_PENDING,
            ERROR,
        } = BANDWIDTH_SETTINGS_STATES;
        let notificationContent = null;
        let className = '';

        switch (updateType) {
            case NUMBER_REG_PENDING:
                notificationContent = <>Purchasing lines for registration <b>{bwSettings.registration_id}</b></>;
                className = 'is-primary';
            break;
        
            default:
                break;
        }

        console.log('notif:', this.props);
        return <Notification {...this.props} className={className}>
            {notificationContent}
        </ Notification>;
    }
}

const mapStateToProps = state => ({
    bwSettingsItems: state.bandwidth.getAllBandwidthRegistrations.data
});

export default connect(
    mapStateToProps,
)(RegistrationUpdateNotification);