import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import CostTools from "./CostTools";
import { CampaignTools } from "./CampaignTools";

import {
  requestCampaignLaunch,
  closeCampaign,
} from "../redux/SelectedCampaign/actions";
import PrelaunchChecklist from "./PrelaunchChecklist";

function Tools({
  campaigns,
  selectedCampaignData,
  selectedCampaignId,
  saveCampaignName,
  deleteCampaign,
  closeCampaign,
  requestCampaignLaunch,
  enterSandboxMode,
  exitSandboxMode,
  history,
  auth,
  vitals,
  showCostTools = false,
}) {
  return (
    <div className="tile is-ancestor">
      <div className="tile is-vertical is-parent">
        <CampaignTools
          campaigns={campaigns}
          deleteCampaign={deleteCampaign}
          requestCampaignLaunch={requestCampaignLaunch}
          deactivateCampaign={closeCampaign}
          saveCampaignName={saveCampaignName}
          enterSandboxMode={enterSandboxMode}
          exitSandboxMode={exitSandboxMode}
          history={history}
          jwt={auth.jwt}
          vitals={vitals}
          {...selectedCampaignData}
        />
        <hr />
        <PrelaunchChecklist selectedCampaignData={selectedCampaignData} />
        {showCostTools && (
          <>
            <hr />
            <CostTools
              edit={false}
              smssurcharge={selectedCampaignData.smssurcharge}
              campaigncosts={selectedCampaignData.campaigncosts}
              totalagenthours={selectedCampaignData.totalagenthours}
              costperagenthour={selectedCampaignData.costperagenthour}
              spendlimit={selectedCampaignData.spendlimit}
              campaignid={selectedCampaignId}
            />
          </>
        )}
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestCampaignLaunch: () => requestCampaignLaunch(),
      closeCampaign: () => closeCampaign(),
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(Tools);
